import styles from './Layout.module.css';
import TopBar from './TopBar/TopBar';
import {Outlet, Navigate} from 'react-router-dom';
import clsx from 'clsx';
import SideBar from './SideBar/SideBar';
import {useUserDetails} from '../../hooks/useUserDetails';
import SideBarExtension from './SideBar/SideBarExtension';
import {useState} from 'react';
import {ExtensionContent} from '../../utils/interfaces/sidebar';

const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [sidebarContent, setSidebarContent] =
    useState<ExtensionContent>('staff');

  const displaySidebarExtension = (content: ExtensionContent) => {
    setSidebarContent(content);
    setSidebarOpen(true);
  };

  const toggleSidebarExtension = (extensionContent: ExtensionContent) => {
    if (sidebarContent === extensionContent && sidebarOpen) {
      setSidebarOpen(false);
    } else {
      displaySidebarExtension(extensionContent);
    }
  };

  const user = useUserDetails();

  return user ? (
    <>
      <TopBar role={user.role} />
      <div className={styles.contentWrapper}>
        <div className={styles.sidebar}>
          <SideBar
            role={user.role}
            displaySidebarExtension={displaySidebarExtension}
            toggleSidebarExtension={toggleSidebarExtension}
            hideSidebarExtension={() => setSidebarOpen(false)}
          />
        </div>
        <div
          onMouseOut={() => setSidebarOpen(false)}
          onMouseOver={() => setSidebarOpen(true)}
          className={clsx({
            [styles.sidebarExtension]: true,
            [styles.sidebarExtensionOpen]: sidebarOpen,
          })}
        >
          <div
            className={clsx({
              [styles.subLinksContainer]: true,
              [styles.subLinksContainerOpen]: sidebarOpen,
            })}
          >
            <SideBarExtension content={sidebarContent} />
          </div>
        </div>
        <div
          className={clsx({
            [styles.contentContainer]: true,
            [styles.parentContentContainer]: user.role === 'urzad',
          })}
        >
          <Outlet />
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/"></Navigate>
  );
};
export default Layout;
