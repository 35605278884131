// children
export const GET_CHILD_DAILY_PRESENCE = '/api/v2/report/childDailyPresence';
export const GET_MONTHLY_PRESENCE_REPORT = '/rest/export/file?type=0';
export const GET_CHILD_TIME_PRESENCE = '/api/v2/report/childTimePresence';
export const GET_LESS_THAN_5_ABSENCES = '/rest/export/file?type=9';
export const GET_CHILD_ABSENCES = '/api/v2/report/childAbsence';
export const GET_CHILD_ABSENCES_COUNT = '/api/v2/report/childAbsenceCount';
export const GET_MEALS_REPORT = '/rest/export/file?type=3';
export const GET_AVERAGE_CHILDREN_PER_HOUR = '/rest/export/file?type=11';
export const GET_DISABLED_CHILDREN = '/api/v2/report/disabledChildren';
export const GET_CHILDREN_WITH_KDR = '/api/v2/report/kdrChildren';

// parents
export const GET_MONTHLY_SETTLEMENT = '/rest/export/file?type=12';
export const GET_MONTHLY_STAY_SETTLEMENT = '/rest/export/file?type=13';
export const GET_MONTHLY_MEALS_SETTLEMENT = '/rest/export/file?type=14';
export const GET_PARENT_PAYMENTS = '/api/v2/report/settlement/parentPayment';
export const GET_PARENT_REFUNDS = '/api/v2/report/settlement/parentRefunds';
export const GET_PARENT_DEBTS = '/api/v2/report/parentDebts';

// settlements
export const GET_CHILDREN_FEES = '/api/v2/report/settlement/childrenFees';
export const GET_DISABLED_CHILDREN_FEES = '/api/v2/report/disabledChildrenFees';
export const GET_CHILDREN_WITH_KDR_FEES = '/api/v2/report/kdrChildrenFees';
export const GET_SOCIAL_ASSISTANCE = '/api/v2/report/socialAssistance';
export const GET_CHILDREN_MONTHLY_SETTLEMENT =
  '/api/v2/report/childMonthSettlement';
export const GET_EMPLOYEE_WORKING_TIME_SETTLEMENT =
  '/api/v2/report/employeeRcp';

// journals
export const GET_ALL_DAYS = '/api/v2/report/journal/childDailyPresence';
export const GET_MONTH_PLANS = '/api/v2/report/journal/monthPlans';
export const GET_DRAFT_DAY_PLAN = '/api/v2/report/journal/draftDayPlans';
export const GET_TRIPS = '/api/v2/report/journal/trips';
export const GET_PARENT_MEETINGS = '/api/v2/report/journal/parentMeetings';
export const GET_PSYCHOLOGICAL_HELP =
  '/api/v2/report/journal/psychologicalHelps';
export const GET_DIAGNOSES = '/api/v2/report/journal/diagnoses';
export const GET_PARENT_CONSULTATIONS =
  '/api/v2/report/journal/parentConsultations';
export const GET_IMPORTANT_EVENTS = '/api/v2/report/journal/importantEvents';

// stats
export const GET_TIMESHEET_HOUR_PERIODS_REPORT =
  '/api/v2/report/statistical/timesheetHourPeriods';
