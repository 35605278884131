import {SidebarTab} from './interfaces/sidebar';
import {
  Briefcase,
  Clipboard,
  Coffee,
  Columns,
  GitHub,
  Home,
  Layers,
  Monitor,
  Users,
} from 'react-feather';
import {Role} from './interfaces/auth';
import {School} from './interfaces/objects/School';

/* eslint-disable  @typescript-eslint/no-unused-vars */
const getOfficeTabs: (school?: School) => SidebarTab[] = school => [
  {
    to: '/urzad/raporty',
    text: 'Raporty',
    icon: Layers,
  },
];

const getStaffTabs: (school?: School) => SidebarTab[] = school => [
  {
    to: '/pulpit/placowka',
    icon: Monitor,
    text: 'Start',
  },

  {
    icon: Coffee,
    text: 'Administracja',
    extensionContent: 'administration',
    linksActive: [
      '/pulpit/kwitariusz',
      '/pulpit/obecnosci',
      '/pulpit/import',
      '/pulpit/raporty',
      '/pulpit/rozliczenia',
    ],
  },

  {
    to: '/pulpit/dziennik',
    icon: Columns,
    text: 'E-dziennik',
  },

  {
    icon: Briefcase,
    text: 'Kadry',
    extensionContent: 'staff',
    linksActive: ['/pulpit/pracownicy', '/pulpit/grafik', '/pulpit/rcp'],
  },

  {
    to: '/pulpit/placowki',
    icon: Home,
    text: 'Placówki',
  },

  {
    to: '/pulpit/dzieci',
    icon: GitHub,
    text: 'Dzieci',
  },

  {
    to: '/pulpit/stolowka',
    icon: Clipboard,
    text: 'Stołówka',
    hidden: !showCanteenTab(school),
  },

  {
    to: '/pulpit/rodzice',
    icon: Users,
    text: 'Rodzice',
  },
];

const showCanteenTab = (school?: School) =>
  school?.schoolType == 2 || school?.schoolSpecify == 2;

const getTabsForRole = (userRole: Role | undefined, school?: School) => {
  switch (userRole) {
    case 'urzad':
      return getOfficeTabs(school);
    case 'administrator':
    case 'opiekun':
    case 'pracownik':
      return getStaffTabs(school);
    default:
      return [];
  }
};

export const getSidebarTabsForUserRole = (
  userRole: Role | undefined,
  school?: School,
): SidebarTab[] => {
  const tabs = getTabsForRole(userRole, school);
  return tabs.filter(tab => !tab.hidden);
};
