export const SIGN_IN_USING_COOKIE = '/';
export const SIGN_IN_USING_JWT = '/uapi/login';
export const IS_AUTHENTICATED = '/isAuthenticated';
export const PASSWORD_RESET = '/rest/user/recovery';
export const LOGOUT = '/logout';
export const GET_SCHOOLS = '/api/v2/schools/withGroups';
export const GET_PARENTS = '/api/v2/parents';
export const GET_JOURNALS = '/api/v2/journals';
export const GET_CANTEEN_CHILDREN = (schoolId: string) =>
  `/api/v2/canteen/school/${schoolId}`;
export const PATCH_CANTEEN_CHILDREN_PAYMENT_STATUS = (
  childId: string,
  value: any,
) => `/api/v2/canteen/child/${childId}?paymentStatus=${value}`;

export const COMPUTE_SCHOOL_SETTLEMENT = (
  schoolId: number,
  year: number,
  month: number,
) => `/api/v2/fees/school/settlement/${schoolId}?year=${year}&month=${month}`;
export const COMPUTE_SCHOOL_SETTLEMENT_AND_SEND_EMAIL = (
  schoolId: number,
  year: number,
  month: number,
) =>
  `/api/v2/fees/school/settlement/${schoolId}/email?year=${year}&month=${month}`;
export const RECOMPUTE_PARENT_SETTLEMENT = (
  schoolId: number,
  parentId: number,
  year: number,
  month: number,
) =>
  `/api/v2/fees/school/${schoolId}/parent/${parentId}/email?year=${year}&month=${month}`;
