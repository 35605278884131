import {
  ReportSelect,
  ReportsSelectOption,
} from '../../../../utils/interfaces/reportSelect';
import {TReportSelect} from '../../../../utils/reportTypes';
import {PROMPT_VALUE, reportFormTypes} from '../../../../utils/reportFormTypes';
import React, {useEffect} from 'react';
import {
  feeReportTypes,
  socialAssistanceCenters,
} from '../../../../utils/mockData';
import ReportsSelect from '../ReportsSelect/ReportsSelect';
import {sortedByName} from '../../../../utils/functions/sorting';
import {
  journalYearToOption,
  parentToOption,
  schoolToOption,
  socialAssistanceToOption,
} from '../../../../utils/mapper/selectOptions';
import {emptySelectOption} from '../../Select/Autocomplete';
import {School} from '../../../../utils/interfaces/objects/School';
import {Parent} from '../../../../utils/interfaces/objects/Parent';
import {Journal} from '../../../../utils/interfaces/objects/Journal';

interface ReportsSelectBoxProps {
  selectedSchool?: School;
  selectList: ReportSelect[];
  selectedValue: TReportSelect;
  setSelectedValue: (value: TReportSelect) => void;
  schools: School[];
  parents: Parent[];
  journals: Journal[];
  fetchParentsInGroups: (groupIds: number[]) => void;
  fetchJournalsForGroup: (groupIds: number[]) => void;
}

const ReportsSelects: React.FC<ReportsSelectBoxProps> = ({
  selectedSchool,
  selectList,
  selectedValue,
  setSelectedValue,
  schools,
  parents,
  journals,
  fetchParentsInGroups,
  fetchJournalsForGroup,
}) => {
  useEffect(() => {
    const groupSelectOption = getInitGroupValueForSchoolId();
    const selectedGroupIds = getSelectedGroupIds(groupSelectOption.value);

    if (selectedValue[ReportSelect.GROUP].value != '') {
      fetchParentsInGroups(selectedGroupIds);
      fetchJournalsForGroup(selectedGroupIds);
    }

    if (selectedSchool) {
      setSelectedValue({
        [ReportSelect.SCHOOL_WITH_ALL]: getInitValueForSelect(
          ReportSelect.SCHOOL_WITH_ALL,
        ),
        [ReportSelect.GROUP]: groupSelectOption,
        [ReportSelect.PARENT]: getInitValueForSelect(ReportSelect.PARENT),
        [ReportSelect.SOCIAL_ASSISTANCE]: getInitValueForSelect(
          ReportSelect.SOCIAL_ASSISTANCE,
        ),
        [ReportSelect.FEE_REPORT_TYPE]: getInitValueForSelect(
          ReportSelect.FEE_REPORT_TYPE,
        ),
        [ReportSelect.JOURNAL]: getInitValueForSelect(ReportSelect.JOURNAL),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  const getInitValueForSelect = (select: ReportSelect) => {
    const selectValues = getAllValuesForSelect(select);
    return selectValues.length >= 1 &&
      reportFormTypes[select].defaultValue.value == PROMPT_VALUE
      ? selectValues[0]
      : reportFormTypes[select].defaultValue;
  };

  const getSelectedGroupIds = (selectedGroupId: string) => {
    if (
      selectedGroupId === reportFormTypes[ReportSelect.GROUP].defaultValue.value
    ) {
      const groupsInSchool = selectedSchool?.groups || [];
      return groupsInSchool.map(group => group.id);
    } else {
      return [+selectedGroupId];
    }
  };

  const getInitGroupValueForSchoolId = () => {
    const groups = getAvailableGroupsForSchool();
    return groups.length == 1
      ? groups[0]
      : reportFormTypes[ReportSelect.GROUP].defaultValue;
  };

  const getAvailableGroupsForSchool = () => {
    return (
      selectedSchool?.groups.map(group => ({
        value: group.id.toString(),
        name: group.name,
      })) || []
    );
  };

  const getAllValuesForSelect = (select: ReportSelect) => {
    const values = getValuesForSelect(select);

    if (reportFormTypes[select].defaultValue.value === PROMPT_VALUE) {
      return values;
    }

    const defaultOption: ReportsSelectOption =
      reportFormTypes[select].defaultValue;

    return [defaultOption, ...values];
  };

  const reduceOptionsToOne = (
    selectValue: ReportsSelectOption[],
  ): ReportsSelectOption => {
    const combinedIdWithLeadingComma = selectValue
      .map(value => value.value)
      .reduce((result: string, next) => result + `,${next}`, '');
    return {
      value: combinedIdWithLeadingComma.length
        ? combinedIdWithLeadingComma.substring(1)
        : emptySelectOption.value,
      name: 'Wszystkie',
    };
  };

  const getUniqueJournalYears = (journalArray: Journal[]) => {
    const uniqueYears: string[] = [];
    journalArray.forEach(journal => {
      if (!uniqueYears.includes(journal.year)) {
        uniqueYears.push(journal.year);
      }
    });
    return uniqueYears;
  };

  const getValuesForSelect = (select: ReportSelect): ReportsSelectOption[] => {
    switch (select) {
      case ReportSelect.SCHOOL_WITH_ALL:
        const schoolOptions = schools.map(schoolToOption);
        return [
          reduceOptionsToOne(schoolOptions),
          ...sortedByName(schoolOptions),
        ];
      case ReportSelect.GROUP:
        return sortedByName(getAvailableGroupsForSchool());
      case ReportSelect.PARENT:
        return sortedByName(parents.map(parentToOption));
      case ReportSelect.SOCIAL_ASSISTANCE:
        return socialAssistanceCenters.map(socialAssistanceToOption);
      case ReportSelect.FEE_REPORT_TYPE:
        return feeReportTypes;
      case ReportSelect.JOURNAL:
        return sortedByName(
          getUniqueJournalYears(journals).map(journalYearToOption),
        ).reverse();
      default:
        return [];
    }
  };

  const handleChange = (
    selectType: ReportSelect,
    value: ReportsSelectOption,
  ) => {
    const newSelectValue = {
      ...selectedValue,
      [selectType]: value,
    };
    // new group should erase selected parent
    if (selectType == ReportSelect.GROUP) {
      newSelectValue[ReportSelect.PARENT] = getInitValueForSelect(
        ReportSelect.PARENT,
      );
      newSelectValue[ReportSelect.JOURNAL] = getInitValueForSelect(
        ReportSelect.JOURNAL,
      );
    }

    setSelectedValue(newSelectValue);
  };

  return (
    <>
      {selectList.map(select => (
        <ReportsSelect
          key={select}
          value={selectedValue[select]}
          handleChange={handleChange}
          disabled={getAllValuesForSelect(select).length <= 1}
          selectOptions={getAllValuesForSelect(select)}
          selectType={select}
        />
      ))}
    </>
  );
};

export default ReportsSelects;
