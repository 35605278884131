import React, {useCallback, useState} from 'react';
import {useAppDispatch} from '../../redux/store';

import styles from './LoginPage.module.css';
import background from '../../assets/images/fox-background.jpg';
import Input from '../../components/shared/Input/Input';
import {Eye, Lock, User} from '../../assets';
import HTTPService from '../../services/HTTPService/HTTPService';
import {logNetworkError} from '../../utils/error';
import {setUserDetails} from '../../redux/slice/authSlice';
import {roleToDashboardMap} from '../../consts/redirect';
import {ResetPasswordModal} from './ResetPasswordModal';
import {UserDetails} from '../../utils/interfaces/auth';
import LocalStorage from '../../services/LocalStorage/LocalStorage';

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const [formErrors, setFormErrors] = useState({
    user: false,
    password: false,
    status: '',
  });
  const [formData, setFormData] = useState({
    identity: '',
    password: '',
  });
  const [passwordHidden, setPasswordHidden] = useState(false);
  const [passwordResetModalHidden, setPasswordResetModalHidden] =
    useState(true);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const userError = !formData.identity;
    const passwordError = !formData.password;
    setFormErrors({user: userError, password: passwordError, status: ''});
    if (!userError && !passwordError) {
      try {
        const [, JWTResponse] = await Promise.all([
          HTTPService.signInUsingCookie(formData),
          HTTPService.signInUsingJWT(formData),
        ]);
        const {payload, token, message} = JWTResponse.data;
        if (!payload || message) {
          setFormErrors({
            user: false,
            password: false,
            status: message || 'Nieznany błąd',
          });
          return;
        }
        HTTPService.assignJWT(token);
        dispatch(setUserDetails({...payload}));
        const userDetails: UserDetails = {...payload};
        LocalStorage.setUserDetails(userDetails);
        window.location.href = roleToDashboardMap[payload.role];
      } catch (error: any) {
        logNetworkError(error);
      }
    }
  };
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }, []);
  const resetPasswordClickHandler = () => {
    setPasswordResetModalHidden(false);
  };
  const endAdornment = (
    <Eye
      onClick={() => {
        setPasswordHidden(!passwordHidden);
      }}
      className={styles.eyeIcon}
    />
  );
  return (
    <div
      style={{backgroundImage: `url(${background})`}}
      className={styles.loginPageContainer}
    >
      <ResetPasswordModal
        hidden={passwordResetModalHidden}
        close={() => setPasswordResetModalHidden(true)}
      />
      <div className={styles.loginFormWrapper}>
        <h1 className={styles.loginFormTitle}>Dzień dobry!</h1>
        <h3 className={styles.loginFormSubtitle}>
          Zaloguj się do swojego konta, aby kontynuować.
        </h3>
        <form onSubmit={onSubmit} className={styles.form}>
          <Input
            onChange={handleChange}
            value={formData.identity}
            placeholder="Nazwa użytkownika lub e-mail"
            name="identity"
            label="Nazwa użytkownika lub e-mail"
            type="text"
            icon={<User className={styles.icon} />}
            errorMessage={'Wpisz nazwę użytkownika lub email'}
            error={formErrors.user}
          />
          <Input
            onChange={handleChange}
            value={formData.password}
            linkText="Nie pamiętasz hasła?"
            placeholder="Haslo"
            name="password"
            link={''}
            onLinkClickHandler={resetPasswordClickHandler}
            label="Hasło"
            type={passwordHidden ? 'text' : 'password'}
            icon={<Lock className={styles.icon} />}
            errorMessage={'Wpisz hasło'}
            error={formErrors.password}
            endAdornment={endAdornment}
          />
          <p>{formErrors.status}</p>
          <button className={styles.submitBtn} type="submit">
            Zaloguj się
          </button>
        </form>
        <div className={styles.linksContainer}>
          <a
            href="https://panlicznik.pl/polityka-prywatnosci/"
            className={styles.link}
          >
            Polityka prywatnosci
          </a>
          <a href="https://panlicznik.pl/regulamin/" className={styles.link}>
            Regulamin
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
