import React from 'react';
import styles from './Autocomplete.module.css';
import {Autocomplete as MuiAutocomplete, TextField} from '@mui/material';
import {ReportsSelectOption} from '../../../utils/interfaces/reportSelect';

export const emptySelectOption: ReportsSelectOption = {
  value: '',
  name: '',
};

type Props = {
  value?: ReportsSelectOption;
  name: string;
  options: any[];
  onChange: (value: any) => void;
  disabled?: boolean;
  label: string;
};

const Autocomplete: React.FC<Props> = ({
  value,
  name,
  options,
  onChange,
  disabled,
  label,
}) => {
  return (
    <MuiAutocomplete
      value={value || emptySelectOption}
      onChange={(event, data) => {
        onChange(data);
      }}
      multiple={false}
      disabled={disabled}
      disablePortal
      disableClearable
      options={[emptySelectOption, ...options]}
      renderOption={(props, option: ReportsSelectOption) => {
        return (
          <li {...props} key={option.value}>
            {option.name}
          </li>
        );
      }}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField name={name} {...params} label={label} />
      )}
      classes={{
        inputRoot: styles.inputRoot,
        input: styles.input,
        option: styles.option,
      }}
    />
  );
};

export default Autocomplete;
