export enum ReportSelect {
  SCHOOL_WITH_ALL = 'school_with_all',
  GROUP = 'group',
  PARENT = 'parent',
  SOCIAL_ASSISTANCE = 'socialAssistance',
  FEE_REPORT_TYPE = 'paymentType',
  JOURNAL = 'journal',
}

export type ReportsSelectOption = {
  value: string;
  name: string;
};
