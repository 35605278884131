import styles from './Searchbar.module.css';
import {Search} from 'react-feather';
import clsx from 'clsx';

export const Searchbar: React.FC<{
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  fullWidth?: boolean;
}> = ({onChange, fullWidth}) => {
  return (
    <div
      className={clsx({
        [styles.searchContainer]: true,
        [styles.searchContainerFullWidth]: fullWidth,
      })}
    >
      <Search
        color="var(--dark-gray)"
        className={clsx({
          [styles.searchIcon]: true,
          [styles.searchIconFullWidth]: fullWidth,
        })}
      />
      <input
        className={clsx({
          [styles.searchBar]: true,
          [styles.searchBarFullWidth]: fullWidth,
        })}
        onChange={onChange}
        placeholder="Szukaj"
      />
    </div>
  );
};

export default Searchbar;
