import React from 'react';
import {Link} from 'react-router-dom';
import styles from './SubLink.module.css';

export const SubLink: React.FC<{
  to: string;
  icon: React.ReactElement;
  text: string;
}> = ({to, icon, text}) => {
  return (
    <div>
      <Link className={styles.subLink} to={to}>
        {icon}
        <p>{text}</p>
      </Link>
    </div>
  );
};

export default SubLink;
