/* eslint-disable  @typescript-eslint/no-explicit-any */

import styles from './Table.module.css';
import {useState, useMemo, useCallback, useEffect} from 'react';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Searchbar} from '../..';
import {IParentData} from '../../../entity/ParentEntity';
import {IChild} from '../../../entity/ChildEntity';
import debounce from 'lodash.debounce';
import {GridCallbackDetails} from '@mui/x-data-grid/models/api';

export const Table: React.FC<{
  columns: GridColDef[];
  headerElements?: any;
  dataRows: any;
  serverSidePagination?: boolean;
  rowCount?: number;
  pageSize?: number;
  pageNumber?: number;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number) => void;
  isLoading?: boolean;
  customSearchFunction?: any;
}> = ({
  columns,
  headerElements,
  dataRows,
  serverSidePagination,
  rowCount,
  pageSize = 10,
  pageNumber,
  onPageChange,
  onPageSizeChange,
  isLoading = false,
  customSearchFunction,
}) => {
  const [rows, setRows] = useState(dataRows);
  useEffect(() => {
    setRows(dataRows);
  }, [dataRows]);
  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newRows = dataRows.filter((row: IParentData | IChild) =>
        Object.values(row).some((value: string | number) =>
          value.toString().includes(e.target.value),
        ),
      );
      setRows(newRows);
    },
    [dataRows],
  );
  const debounceHandleSearch = useMemo(
    () => debounce(handleSearch, 300),
    [handleSearch],
  );
  const noRowsFound = () => {
    return <h3 style={{textAlign: 'center'}}>Brak wyników</h3>;
  };
  return (
    <>
      <div className={styles.tableContainer}>
        <div className={styles.tableTop}>
          <div style={{display: 'flex'}}>{headerElements}</div>
          <Searchbar onChange={customSearchFunction || debounceHandleSearch} />
        </div>
        <DataGrid
          components={{NoRowsOverlay: noRowsFound}}
          sx={{
            border: 'none',
            fontFamily: 'Quicksand',
            fontWeight: 900,
            color: 'var(--dark-gray)',
            ' &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-iconButtonContainer:focus':
              {
                outline: 'none',
              },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              display: 'flex',
              justifyContent: 'center',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 900,
              fontFamily: 'Quicksand',
              color: 'var(--primary-blue)',
            },
          }}
          paginationMode={serverSidePagination ? 'server' : 'client'}
          rowCount={rowCount}
          disableColumnMenu
          pageSize={pageSize}
          page={pageNumber}
          columns={columns}
          rows={rows}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          disableSelectionOnClick
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default Table;
