import {SocialAssistance} from './interfaces/objects/SocialAssistance';
import {FeeReportType} from './interfaces/objects/FeeReportType';
import {Parent} from './interfaces/objects/Parent';
import {CanteenChild} from './interfaces/objects/CanteenChild';

export const canteenChildren: CanteenChild[] = [
  {
    id: 1,
    childId: '1',
    firstname: 'Janusz',
    lastname: 'Testowy',
    excuseAbsence: false,
    paymentOverdue: true,
  },
  {
    id: 1,
    childId: '1',
    firstname: 'Janusz',
    lastname: 'Testowy',
    excuseAbsence: false,
    paymentOverdue: true,
  },
  {
    id: 1,
    childId: '1',
    firstname: 'Janusz',
    lastname: 'Testowy',
    excuseAbsence: false,
    paymentOverdue: true,
  },
  {
    id: 1,
    childId: '1',
    firstname: 'Janusz',
    lastname: 'Testowy',
    excuseAbsence: false,
    paymentOverdue: true,
  },
  {
    id: 1,
    childId: '1',
    firstname: 'Janusz',
    lastname: 'Testowy',
    excuseAbsence: false,
    paymentOverdue: true,
  },
  {
    id: 1,
    childId: '1',
    firstname: 'Janusz',
    lastname: 'Testowy',
    excuseAbsence: false,
    paymentOverdue: true,
  },
  {
    id: 1,
    childId: '1',
    firstname: 'Janusz',
    lastname: 'Testowy',
    excuseAbsence: false,
    paymentOverdue: true,
  },
  {
    id: 1,
    childId: '1',
    firstname: 'Janusz',
    lastname: 'Testowy',
    excuseAbsence: false,
    paymentOverdue: true,
  },
  {
    id: 1,
    childId: '1',
    firstname: 'Janusz',
    lastname: 'Testowy',
    excuseAbsence: false,
    paymentOverdue: true,
  },
  {
    id: 1,
    childId: '1',
    firstname: 'Janusz',
    lastname: 'Testowy',
    excuseAbsence: false,
    paymentOverdue: true,
  },
];
export const parents: Parent[] = [
  {
    id: 1,
    login: 'user',
    firstname: 'Anna',
    lastname: 'Kud',
    email: 'test@gmail.com',
    phone: '123123123',
    address: 'Spokojna 10/2',
    postcode: '11-111',
    city: 'Wrocław',
    voivodeship: 'dolnośląskie',
    bigFamilyCard: false,
  },
  {
    id: 2,
    login: 'user',
    firstname: 'Tomek',
    lastname: 'Kud',
    email: 'test@gmail.com',
    phone: '123123123',
    address: 'Spokojna 10/2',
    postcode: '11-111',
    city: 'Wrocław',
    voivodeship: 'dolnośląskie',
    bigFamilyCard: false,
  },
  {
    id: 3,
    login: 'user',
    firstname: 'Rafał',
    lastname: 'Kud',
    email: 'test@gmail.com',
    phone: '123123123',
    address: 'Spokojna 10/2',
    postcode: '11-111',
    city: 'Wrocław',
    voivodeship: 'dolnośląskie',
    bigFamilyCard: false,
  },
  {
    id: 4,
    login: 'user',
    firstname: 'Marcin',
    lastname: 'Kud',
    email: 'test@gmail.com',
    phone: '123123123',
    address: 'Spokojna 10/2',
    postcode: '11-111',
    city: 'Wrocław',
    voivodeship: 'dolnośląskie',
    bigFamilyCard: false,
  },
  {
    id: 5,
    login: 'user',
    firstname: 'Michał',
    lastname: 'Kud',
    email: 'test@gmail.com',
    phone: '123123123',
    address: 'Spokojna 10/2',
    postcode: '11-111',
    city: 'Wrocław',
    voivodeship: 'dolnośląskie',
    bigFamilyCard: false,
  },
  {
    id: 6,
    login: 'user',
    firstname: 'Jola',
    lastname: 'Kud',
    email: 'test@gmail.com',
    phone: '123123123',
    address: 'Spokojna 10/2',
    postcode: '11-111',
    city: 'Wrocław',
    voivodeship: 'dolnośląskie',
    bigFamilyCard: false,
  },
  {
    id: 7,
    login: 'user',
    firstname: 'Zygmunt',
    lastname: 'Kud',
    email: 'test@gmail.com',
    phone: '123123123',
    address: 'Spokojna 10/2',
    postcode: '11-111',
    city: 'Wrocław',
    voivodeship: 'dolnośląskie',
    bigFamilyCard: false,
  },
  {
    id: 8,
    login: 'user',
    firstname: 'Patryk',
    lastname: 'Kud',
    email: 'test@gmail.com',
    phone: '123123123',
    address: 'Spokojna 10/2',
    postcode: '11-111',
    city: 'Wrocław',
    voivodeship: 'dolnośląskie',
    bigFamilyCard: false,
  },
  {
    id: 9,
    login: 'user',
    firstname: 'Honorata',
    lastname: 'Kud',
    email: 'test@gmail.com',
    phone: '123123123',
    address: 'Spokojna 10/2',
    postcode: '11-111',
    city: 'Wrocław',
    voivodeship: 'dolnośląskie',
    bigFamilyCard: false,
  },
  {
    id: 10,
    login: 'user',
    firstname: 'Weronika',
    lastname: 'Kud',
    email: 'test@gmail.com',
    phone: '123123123',
    address: 'Spokojna 10/2',
    postcode: '11-111',
    city: 'Wrocław',
    voivodeship: 'dolnośląskie',
    bigFamilyCard: false,
  },
];
export const children = [
  {
    id: 1,
    firstname: 'Tomek',
    lastname: 'Testowy',
    sex: 0,
    card_id: 123,
    parent_id: 32,
    group_id: 123,
    dinner: false,
    absence: true,
    absence_dates: '26/05/2022',
    absenceCalledToday: false,
    absenceCalledTommorow: false,
    parent_fullname: 'Anna Kud',
    outpost_group: 'Przedszkole nr. 72 (Tygryski)',
    stay_time: '18 godz',
    pay_time: '11 godz / 11 zł',
    dinner_reconing: '123 zł',
  },
  {
    id: 2,
    firstname: 'Tomek',
    lastname: 'Testowy',
    sex: 0,
    card_id: 123,
    parent_id: 32,
    group_id: 123,
    dinner: false,
    absence: true,
    absence_dates: '26/05/2022',
    absenceCalledToday: false,
    absenceCalledTommorow: false,
    parent_fullname: 'Anna Kud',
    outpost_group: 'Przedszkole nr. 72 (Tygryski)',
    stay_time: '18 godz',
    pay_time: '11 godz / 11 zł',
    dinner_reconing: '123 zł',
  },
  {
    id: 3,
    firstname: 'Tomek',
    lastname: 'Testowy',
    sex: 0,
    card_id: 123,
    parent_id: 32,
    group_id: 123,
    dinner: false,
    absence: true,
    absence_dates: '26/05/2022',
    absenceCalledToday: true,
    absenceCalledTommorow: false,
    parent_fullname: 'Anna Kud',
    outpost_group: 'Przedszkole nr. 72 (Tygryski)',
    stay_time: '18 godz',
    pay_time: '11 godz / 11 zł',
    dinner_reconing: '123 zł',
  },
  {
    id: 4,
    firstname: 'Tomek',
    lastname: 'Testowy',
    sex: 0,
    card_id: 123,
    parent_id: 32,
    group_id: 123,
    dinner: false,
    absence: false,
    absence_dates: '26/05/2022',
    absenceCalledToday: true,
    absenceCalledTommorow: true,
    parent_fullname: 'Anna Kud',
    outpost_group: 'Przedszkole nr. 72 (Tygryski)',
    stay_time: '18 godz',
    pay_time: '11 godz / 11 zł',
    dinner_reconing: '123 zł',
  },
  {
    id: 5,
    firstname: 'Tomek',
    lastname: 'Testowy',
    sex: 0,
    card_id: 123,
    parent_id: 32,
    group_id: 123,
    dinner: false,
    absence: false,
    absence_dates: '26/05/2022',
    absenceCalledToday: true,
    absenceCalledTommorow: false,
    parent_fullname: 'Anna Kud',
    outpost_group: 'Przedszkole nr. 72 (Tygryski)',
    stay_time: '18 godz',
    pay_time: '11 godz / 11 zł',
    dinner_reconing: '123 zł',
  },
  {
    id: 6,
    firstname: 'Tomek',
    lastname: 'Testowy',
    sex: 0,
    card_id: 123,
    parent_id: 32,
    group_id: 123,
    dinner: false,
    absence: false,
    absence_dates: '26/05/2022',
    absenceCalledToday: false,
    absenceCalledTommorow: false,
    parent_fullname: 'Anna Kud',
    outpost_group: 'Przedszkole nr. 72 (Tygryski)',
    stay_time: '18 godz',
    pay_time: '11 godz / 11 zł',
    dinner_reconing: '123 zł',
  },
  {
    id: 7,
    firstname: 'Tomek',
    lastname: 'Testowy',
    sex: 0,
    card_id: 123,
    parent_id: 32,
    group_id: 123,
    dinner: false,
    absence: false,
    absence_dates: '26/05/2022',
    absenceCalledToday: false,
    absenceCalledTommorow: false,
    parent_fullname: 'Anna Kud',
    outpost_group: 'Przedszkole nr. 72 (Tygryski)',
    stay_time: '18 godz',
    pay_time: '11 godz / 11 zł',
    dinner_reconing: '123 zł',
  },
];

export const annoucements = [
  {
    id: 1,
    type: 0,
    title: 'Test Annoucement',
    message: 'test',
    innerHTML: '<strong>OGŁOSZENIE</strong>',
    group_id: 2,
    group_name: 'Tygryski',
    school_id: 1,
    created_at: new Date(2022, 11, 17),
    updated_at: new Date(2022, 12, 17),
  },
  {
    id: 2,
    type: 0,
    title: 'Test Annoucement 2',
    message: 'test',
    innerHTML: '<strong>OGŁOSZENIE</strong>',
    group_id: 2,
    group_name: 'Tygryski',
    school_id: 1,
    created_at: new Date(2022, 11, 17),
    updated_at: new Date(2022, 12, 17),
  },
  {
    id: 3,
    type: 0,
    title: 'Test Annoucement 3',
    message: 'test',
    innerHTML: '<strong>OGŁOSZENIE</strong>',
    group_id: 2,
    group_name: 'Tygryski',
    school_id: 1,
    created_at: new Date(2022, 11, 17),
    updated_at: new Date(2022, 12, 17),
  },
  {
    id: 4,
    type: 0,
    title: 'Test Annoucement 4',
    message: 'test',
    innerHTML: '<strong>OGŁOSZENIE</strong>',
    group_id: 2,
    group_name: 'Tygryski',
    school_id: 1,
    created_at: new Date(2022, 11, 17),
    updated_at: new Date(2022, 12, 17),
  },
  {
    id: 5,
    type: 0,
    title: 'Test Annoucement 5',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lobortis enim sed augue iaculis, sed eleifend velit tincidunt. Nam tristique faucibus tristique. Aliquam erat volutpat. Donec tempor orci felis, consectetur fermentum lorem euismod iaculis. Phasellus non scelerisque nibh. Ut congue erat in augue ullamcorper, et ultrices lorem tristique. Ut at eros lacinia, elementum sapien a, vestibulum est. Phasellus ac lacus enim. Etiam non sagittis lacus, non sagittis nibh.',
    innerHTML: '<strong>OGŁOSZENIE</strong>',
    group_id: 2,
    group_name: 'Tygryski',
    school_id: 1,
    created_at: new Date(2022, 11, 17),
    updated_at: new Date(2022, 12, 17),
  },
];

export const socialAssistanceCenters: SocialAssistance[] = [
  {
    name: 'MOPS',
  },
  {
    name: 'GOPS',
  },
  {
    name: 'MOPR',
  },
];

export const feeReportTypes: FeeReportType[] = [
  {
    value: 'stay',
    name: 'Pobyt',
  },
  {
    value: 'meals',
    name: 'Posiłki',
  },
];

export const outposts = [
  {
    id: 1,
    name: 'Zespół Szkół nr 17 na Podlasiu',
  },
  {
    id: 2,
    name: 'Zespół Szkół nr 17 na Mazowszu',
  },
];

export const messages = [
  {
    id: 1,
    send_from: 'user',
    send_to: 'admin',
    text: 'Dzień dobry, zgłaszam nieobecność dziecka ',
    created_at: new Date(2022, 11, 17),
  },
  {
    id: 2,
    send_from: 'user',
    send_to: 'admin',
    text: 'Zgłaszam nieobecność dziecka w dniu 25/05/2022',
    created_at: new Date(2022, 11, 17),
  },
  {
    id: 3,
    send_from: 'admin',
    send_to: 'user',
    text: 'Dzień dobry, nieobecność została zarejestrowana',
    created_at: new Date(2022, 11, 17),
  },
  {
    id: 4,
    send_from: 'user',
    send_to: 'admin',
    text: 'Zgłaszam nieobecność dziecka w dniu 25/05/2022',
    created_at: new Date(2022, 11, 17),
  },
  {
    id: 5,
    send_from: 'admin',
    send_to: 'user',
    text: 'Dzień dobry, nieobecność została zarejestrowana',
    created_at: new Date(2022, 11, 17),
  },
  {
    id: 6,
    send_from: 'user',
    send_to: 'admin',
    text: 'Zgłaszam nieobecność dziecka w dniu 25/05/2022',
    created_at: new Date(2022, 11, 17),
  },
  {
    id: 7,
    send_from: 'admin',
    send_to: 'user',
    text: 'Dzień dobry, nieobecność została zarejestrowana',
    created_at: new Date(2022, 11, 17),
  },
];
export const messages2 = [
  {
    id: 1,
    send_from: 'user',
    send_to: 'admin',
    text: 'Dzień dobry, chciałbym zgłosić błąd w aplikacji',
    created_at: new Date(2022, 11, 17),
  },
  {
    id: 2,
    send_from: 'user',
    send_to: 'admin',
    text: 'Przy wybieraniu dziecka, pojawiają się niepoprawne grupy',
    created_at: new Date(2022, 11, 17),
  },
  {
    id: 3,
    send_from: 'admin',
    send_to: 'user',
    text: 'Dzień dobry, błąd został już zgłoszony do naprawy',
    created_at: new Date(2022, 11, 17),
  },
  {
    id: 3,
    send_from: 'admin',
    send_to: 'user',
    text: 'Dzień dobry, błąd został już naprawiony',
    created_at: new Date(2022, 11, 17),
  },
];
export const chatInstances = [
  {
    id: 1,
    firstname: 'Tomasz',
    lastname: 'Kud',
    updated_at: new Date(2022, 11, 17),
    last_message: messages[messages.length - 1],
    messages: messages2,
  },
  {
    id: 2,
    firstname: 'Anna',
    lastname: 'Kud',
    updated_at: new Date(2022, 11, 17),
    last_message: messages[messages.length - 1],
    messages: messages,
  },
  {
    id: 3,
    firstname: 'Piotr',
    lastname: 'Kud',
    updated_at: new Date(2022, 11, 17),
    last_message: messages[messages.length - 1],
    messages: messages2,
  },
  {
    id: 4,
    firstname: 'Renata',
    lastname: 'Kud',
    updated_at: new Date(2022, 11, 17),
    last_message: messages[messages.length - 1],
    messages: messages,
  },
  {
    id: 5,
    firstname: 'Rafał',
    lastname: 'Kud',
    updated_at: new Date(2022, 11, 17),
    last_message: messages[messages.length - 1],
    messages: messages2,
  },
];
export const transactions = [
  {
    id: 1,
    amount: 100,
    date: new Date(2022, 11, 17),
    type: 'Obiady',
    title: 'Przelew za obiady',
  },
  {
    id: 2,
    amount: 320,
    date: new Date(2022, 11, 17),
    type: 'Obiady',
    title: 'Przelew za obiady',
  },
  {
    id: 3,
    amount: 420,
    date: new Date(2022, 11, 17),
    type: 'Obiady',
    title: 'Przelew za obiady',
  },
];
export const userBalance = {
  id: 1,
  main_balance: 100,
  stay_balance: 20,
  meal_balance: 80,
};
export const parentReconing = {
  id: 1,
  payments_in_total: 730,
  to_pay: 210,
  interest: 10,
  detailed_reconing: 510,
  paid_stay_time: 300,
  dinners: 210,
};
