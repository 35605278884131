export const pl_lowercase = {
  name: 'pl_lowercase',
  months: [
    ['styczeń', 'sty'],
    ['luty', 'lut'],
    ['marzec', 'mar'],
    ['kwiecień', 'kwi'],
    ['maj', 'maj'],
    ['czerwiec', 'cze'],
    ['lipiec', 'lip'],
    ['sierpień', 'sie'],
    ['wrzesień', 'wrz'],
    ['październik', 'paź'],
    ['listopad', 'lis'],
    ['grudzień', 'gru'],
  ],
  weekDays: [
    ['sobota', 'sob'],
    ['niedziela', 'niedz'],
    ['poniedziałek', 'pon'],
    ['wtorek', 'wt'],
    ['środa', 'śr'],
    ['czwartek', 'czw'],
    ['piątek', 'pt'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
};
