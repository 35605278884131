import {School} from '../interfaces/objects/School';
import {ReportsSelectOption} from '../interfaces/reportSelect';
import {Parent} from '../interfaces/objects/Parent';
import {Group} from '../interfaces/objects/Group';
import {SocialAssistance} from '../interfaces/objects/SocialAssistance';

export const schoolToOption = (school: School): ReportsSelectOption => ({
  value: school.id.toString(),
  name: school.name,
});

export const parentToOption = (parent: Parent): ReportsSelectOption => ({
  value: parent.id.toString(),
  name: `${parent.lastname} ${parent.firstname}`,
});

export const groupToOption = (group: Group): ReportsSelectOption => ({
  value: group.id.toString(),
  name: group.name,
});

export const socialAssistanceToOption = (
  socialAssistance: SocialAssistance,
): ReportsSelectOption => ({
  value: socialAssistance.name,
  name: socialAssistance.name,
});

export const journalYearToOption = (
  journalYear: string,
): ReportsSelectOption => ({
  value: journalYear,
  name: journalYear,
});
