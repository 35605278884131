import styles from '../TopBar.module.css';
import {useContext, useEffect, useState} from 'react';
import {SchoolContext} from '../../../../context/SchoolContextProvider';
import Autocomplete from '../../../shared/Select/Autocomplete';
import {ReportsSelectOption} from '../../../../utils/interfaces/reportSelect';

export const SchoolSelect = () => {
  const {selectedSchool, changeSchool, schools} = useContext(SchoolContext);
  const [schoolItems, setSchoolItems] = useState<any>([]);
  const selectedSchoolOption = {
    value: selectedSchool?.id.toString() || '',
    name: selectedSchool?.name || '',
  };

  useEffect(() => {
    const schoolItemsMapped = schools.map(school => {
      return {value: school.id, name: school.name};
    });
    setSchoolItems(schoolItemsMapped);
  }, [schools]);

  const selectSchool = (selectedOption: ReportsSelectOption) => {
    changeSchool(+selectedOption.value);
  };

  return (
    <div className={styles.selectContainer}>
      <Autocomplete
        options={schoolItems}
        onChange={selectSchool}
        value={selectedSchoolOption}
        name={selectedSchoolOption?.name}
        label={''}
      />
    </div>
  );
};
