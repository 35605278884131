import React, {MouseEventHandler} from 'react';
import {Link, useLocation} from 'react-router-dom';
import styles from '../Layout.module.css';
import clsx from 'clsx';

export const SideBarLink: React.FC<{
  to: string;
  icon: React.ReactElement;
  text: string;
  linksActive?: string[];
  onMouseOut?: MouseEventHandler;
  onClick?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
}> = ({to, icon, text, linksActive, onMouseOut, onClick, onMouseOver}) => {
  const currentPath = useLocation().pathname;

  return (
    <div>
      <Link
        to={to}
        className={clsx({
          [styles.sidebarLink]: true,
          [styles.sidebarLinkActive]: !linksActive
            ? currentPath === to
            : linksActive.includes(currentPath),
        })}
        onMouseOut={onMouseOut}
        onClick={onClick}
        onMouseOver={onMouseOver}
      >
        {icon}
        <p>{text}</p>
      </Link>
    </div>
  );
};
