import styles from './ReportsForm.module.css';
import {Button} from '../../../index';
import {SyncLoader} from 'react-spinners';
import React from 'react';

interface ReportsButtonLineProps {
  onClick: () => void;
  boolean: boolean;
  downloadingInProgress: boolean;
}

const ReportsButtonLine: React.FC<ReportsButtonLineProps> = ({
  onClick,
  boolean,
  downloadingInProgress,
}) => {
  return (
    <div className={styles.submitBtnContainer}>
      <Button
        className={styles.submitBtn}
        onClick={onClick}
        text="Generuj raport"
        disabled={!boolean || downloadingInProgress}
      />
      {downloadingInProgress && (
        <SyncLoader className={styles.loadingIcon} size={5} />
      )}
    </div>
  );
};

export default ReportsButtonLine;
