import {PageTitle} from '../../components';
import styles from './ReportsPage.module.css';
import ReportsMenu from '../../components/shared/Reports/ReportsMenu/ReportsMenu';
import ReportsTab from '../../components/shared/Reports/ReportsTab/ReportsTab';
import {useState} from 'react';
import {getDefaultReportForUserRole} from '../../utils/reportsTabs';
import {TReportsTab} from '../../utils/reportTypes';
import {useUserDetails} from '../../hooks/useUserDetails';

export const ReportsPage = () => {
  const userDetails = useUserDetails();
  const reportTypeDataItem: TReportsTab = getDefaultReportForUserRole(
    userDetails?.role,
  );
  const [tabData, setTabData] = useState<TReportsTab>(reportTypeDataItem);
  const onItemClick = (item: TReportsTab) => {
    setTabData(item);
  };

  return (
    <>
      <PageTitle title="Raporty" />
      <div className={styles.reportsContainer}>
        <ReportsMenu
          title="Rodzaje raportu:"
          onItemClick={onItemClick}
          activeTab={tabData?.id}
          userDetails={userDetails}
        />
        <ReportsTab tabData={tabData} />
      </div>
    </>
  );
};

export default ReportsPage;
