import {ReportSelect, ReportsSelectOption} from './interfaces/reportSelect';
import {ReportSwitch} from './interfaces/reportSwitch';
import {emptySelectOption} from '../components/shared/Select/Autocomplete';

export const emptyReportSelect = {
  [ReportSelect.SCHOOL_WITH_ALL]: emptySelectOption,
  [ReportSelect.GROUP]: emptySelectOption,
  [ReportSelect.PARENT]: emptySelectOption,
  [ReportSelect.SOCIAL_ASSISTANCE]: emptySelectOption,
  [ReportSelect.FEE_REPORT_TYPE]: emptySelectOption,
  [ReportSelect.JOURNAL]: emptySelectOption,
};

export type TReportSelect = Record<ReportSelect, ReportsSelectOption>;

export const emptyReportSwitch = {
  [ReportSwitch.DETAILED_HOURLY_REPORT]: false,
  [ReportSwitch.DETAILED_MEALS_REPORT]: false,
  [ReportSwitch.WITH_BALANCES]: false,
};

export type TReportSwitch = {
  [ReportSwitch.DETAILED_HOURLY_REPORT]: boolean;
  [ReportSwitch.DETAILED_MEALS_REPORT]: boolean;
  [ReportSwitch.WITH_BALANCES]: boolean;
};

export type TReportsTab = {
  id: number;
  title: string;
  description: string;
  fields: {
    date: boolean;
    dateRange: boolean;
    month: boolean;
    selectList: ReportSelect[];
    switchList?: ReportSwitch[];
  };
  requestUrl: string;
};

export type TReportTypeData = {
  tabTitle: string;
  items: TReportsTab[];
};
