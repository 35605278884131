import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../store';
import {UserDetails} from '../../utils/interfaces/auth';

type AuthState = {
  user?: UserDetails;
};

const initialState: AuthState = {
  user: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserDetails: (state: AuthState, action: PayloadAction<UserDetails>) => {
      state.user = action.payload;
    },
  },
});

export const {setUserDetails} = authSlice.actions;

export const selectState = (state: RootState) => state.auth;
export default authSlice.reducer;
