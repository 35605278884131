import httpService from './HTTPService';
import localStorage from '../LocalStorage/LocalStorage';
import {AxiosResponse} from 'axios';

const UNAUTHORIZED_MESSAGE =
  'Utracono połączenie z sesją, zaloguj się ponownie.';

class SessionService {
  public async isTokenExpired(response: AxiosResponse): Promise<boolean> {
    let jsonResponse: string;
    if (response.data instanceof Blob) {
      jsonResponse = await response.data.text();
    } else jsonResponse = JSON.stringify(response.data);

    return jsonResponse.includes(UNAUTHORIZED_MESSAGE);
  }
  public logoutUser() {
    return httpService
      .logout()
      .then(() => {
        localStorage.clear();
      })
      .catch(err => {
        console.error("Couldn't log out");
        console.error(err);
      });
  }
}

export default new SessionService();
