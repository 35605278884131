import styles from './ReportsTab.module.css';
import ReportsForm from '../ReportsForm/ReportsForm';
import {TReportsTab} from '../../../../utils/reportTypes';
import {useContext, useState} from 'react';
import {SchoolContext} from '../../../../context/SchoolContextProvider';
import HTTPService from '../../../../services/HTTPService/HTTPService';
import {Parent} from '../../../../utils/interfaces/objects/Parent';
import {Journal} from '../../../../utils/interfaces/objects/Journal';

export const ReportsTab: React.FC<{
  tabData: TReportsTab;
}> = ({tabData: {title, description, fields, requestUrl}}) => {
  const {schools} = useContext(SchoolContext);
  const [parents, setParents] = useState<Parent[]>([]);
  const [journals, setJournals] = useState<Journal[]>([]);

  const fetchParentsInGroups = (groupIds: number[]) => {
    HTTPService.getParentForSchool(groupIds)
      .then(res => {
        const {data: newParents} = res;
        setParents(newParents);
      })
      .catch(err => {
        console.error(err);
        setParents([]);
      });
  };
  const fetchJournalsForGroup = (groupIds: number[]) => {
    HTTPService.getJournalsForGroup(groupIds)
      .then(res => {
        setJournals(res.data);
      })
      .catch(err => {
        console.error(err);
        setJournals([]);
      });
  };

  return (
    <div className={styles.tabContainer}>
      <div className={styles.headerContainer}>
        <h2 className={styles.header}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      <ReportsForm
        fields={fields}
        requestUrl={requestUrl}
        schools={schools}
        parents={parents}
        journals={journals}
        fetchParentsInGroups={fetchParentsInGroups}
        fetchJournalsForGroup={fetchJournalsForGroup}
      />
    </div>
  );
};

export default ReportsTab;
