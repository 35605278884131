import styles from './Button.module.css';
import React from 'react';
import clsx from 'clsx';

const Button: React.FC<{
  text: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  className?: string;
  variant?: string;
  onClick?: () => void;
  icon?: React.ReactElement;
}> = ({text, style, disabled, className, variant, onClick, icon}) => {
  return (
    <button
      onClick={onClick}
      style={style}
      disabled={disabled}
      className={
        !disabled
          ? clsx({
              [styles.btn]: true,
              [styles.btnWhite]: variant === 'white',
              [styles.btnGreen]: variant === 'green',
              [className as string]: className,
            })
          : clsx({
              [styles.btnDisabled]: true,
              [className as string]: className,
            })
      }
    >
      {icon}
      {text}
    </button>
  );
};

export default React.memo(Button);
