import {Role} from '../../../utils/interfaces/auth';
import styles from '../Layout.module.css';
import {SideBarLink} from './SideBarLink';
import {ExtensionContent} from '../../../utils/interfaces/sidebar';
import {getSidebarTabsForUserRole} from '../../../utils/sidebarTabs';
import hash from 'object-hash';
import {useContext} from 'react';
import {SchoolContext} from '../../../context/SchoolContextProvider';

type SideBarProps = {
  role: Role;
  displaySidebarExtension: (content: ExtensionContent) => void;
  toggleSidebarExtension: (content: ExtensionContent) => void;
  hideSidebarExtension: () => void;
};

export const SideBar = ({
  role,
  displaySidebarExtension,
  toggleSidebarExtension,
  hideSidebarExtension,
}: SideBarProps) => {
  const {selectedSchool} = useContext(SchoolContext);
  const userTabs = getSidebarTabsForUserRole(role, selectedSchool);

  return (
    <>
      {userTabs.map(tab => (
        <SideBarLink
          key={hash(tab)}
          to={tab.to ? tab.to : location.pathname}
          icon={<tab.icon className={styles.linkIcon} />}
          text={tab.text}
          {...(tab.extensionContent
            ? {
                onMouseOver: () =>
                  displaySidebarExtension(
                    tab.extensionContent as ExtensionContent,
                  ),
                onClick: () =>
                  toggleSidebarExtension(
                    tab.extensionContent as ExtensionContent,
                  ),
                onMouseOut: hideSidebarExtension,
              }
            : {})}
          {...(tab.linksActive ? {linksActive: tab.linksActive} : {})}
        />
      ))}
    </>
  );
};
export default SideBar;
