import styles from './CanteenStatusList.module.css';
import {pl_lowercase} from '../../../utils/datePicker';
import DatePicker, {DateObject} from 'react-multi-date-picker';
import {columns} from './TableColumns';
import {Input, Table} from '../../index';
import {Calendar} from 'react-feather';
import {useContext, useEffect, useMemo, useState} from 'react';
import {SchoolContext} from '../../../context/SchoolContextProvider';
import {usePagination} from '../../../hooks/usePagination';
import HTTPService from '../../../services/HTTPService/HTTPService';
import {parseDate} from '../../../utils/dateUtils';
import debounce from 'lodash.debounce';
import {CanteenChildPaginated} from '../../../utils/interfaces/objects/CanteenChild';

export const CanteenStatusList = () => {
  const {selectedSchool} = useContext(SchoolContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const {
    currentPage,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    searchText,
  } = usePagination();
  const [rows, setRows] = useState<CanteenChildPaginated | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const onSwitcherChangeHandler = async ({childId, value}: any) => {
    const newRows = [...(rows?.values || [])];
    const index = rows?.values.findIndex(row => row.childId === childId) || 0;
    newRows[index].paymentOverdue = value;
    try {
      await HTTPService.updateCanteenPaymentStatus({childId, value});
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (selectedSchool) {
      setIsLoading(true);
      HTTPService.getCanteenChildren({
        schoolId: selectedSchool.id.toString(),
        date: parseDate(selectedDate),
        pageNumber: currentPage,
        pageSize: pageSize,
        query: searchText,
      })
        .then(res => {
          setIsLoading(false);
          setRows(res?.data);
        })
        .catch(err => {
          setIsLoading(false);
          console.error(err);
          setRows(null);
        });
    }
  }, [selectedDate, currentPage, pageSize, selectedSchool, searchText]);

  const renderInputForDatePicker = (
    name: string,
    placeholder: string,
    label: string,
  ) => {
    return (
      <Input
        endAdornment={<Calendar className={styles.inputCalendar} />}
        name={name}
        type="text"
        placeholder={placeholder}
        modalInput
        label={label}
        customLabelStyle={styles.dateInputLabelOnBorder}
      />
    );
  };

  const debounceHandleSearch = useMemo(
    () => debounce(onSearchChange, 500),
    [onSearchChange],
  );

  return (
    <div>
      <Table
        dataRows={rows?.values || []}
        headerElements={
          <>
            <DatePicker
              locale={pl_lowercase}
              value={selectedDate}
              onChange={(date: DateObject) => setSelectedDate(date.toDate())}
              multiple={false}
              containerClassName={styles.datePickerContainer}
              render={renderInputForDatePicker(
                'date',
                'Wybierz dzień',
                'Dzień',
              )}
            />
          </>
        }
        columns={columns(onSwitcherChangeHandler)}
        serverSidePagination={true}
        pageNumber={currentPage}
        pageSize={pageSize}
        rowCount={rows?.totalElements || 0}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        isLoading={isLoading}
        customSearchFunction={debounceHandleSearch}
      />
    </div>
  );
};
