import React from 'react';
import styles from './Input.module.css';
import clsx from 'clsx';

interface InputProps {
  name: string;
  type: 'email' | 'password' | 'number' | 'text';
  label?: string;
  icon?: React.ReactElement;
  placeholder?: string;
  linkText?: string;
  link?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  error?: boolean;
  modalInput?: boolean;
  chatInput?: boolean;
  endAdornment?: React.ReactElement;
  openCalendar?: () => void;
  step?: number;
  pattern?: string;
  onLinkClickHandler?: () => void;
  customLabelStyle?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  type,
  label,
  icon,
  placeholder,
  link,
  linkText,
  value,
  onChange,
  error,
  errorMessage,
  modalInput,
  chatInput,
  endAdornment,
  openCalendar,
  step,
  pattern,
  onLinkClickHandler,
  customLabelStyle,
}) => {
  return (
    <div
      className={clsx({
        [styles.inputGroupWrapper]: true,
        [styles.modalInputGroupWrapper]: modalInput,
        [styles.chatInputGroupWrapper]: chatInput,
      })}
    >
      {linkText ? (
        <div className={styles.inputRow}>
          <label className={styles.label}>{label}</label>
          <a
            className={styles.link}
            href={link}
            onClick={event => {
              if (onLinkClickHandler) {
                event.preventDefault();
                onLinkClickHandler();
              }
            }}
          >
            {linkText}
          </a>
        </div>
      ) : (
        <label
          className={clsx({
            [styles.label]: !customLabelStyle,
            [customLabelStyle as string]: customLabelStyle,
            [styles.modalLabel]: modalInput,
            [styles.chatLabel]: chatInput,
          })}
        >
          {label}
        </label>
      )}
      {icon}
      <input
        onFocus={openCalendar}
        name={name}
        onChange={onChange}
        readOnly={!onChange}
        value={value}
        step={step}
        pattern={pattern}
        placeholder={placeholder}
        className={clsx({
          [styles.modalInput]: modalInput,
          [styles.input]: true,
          [styles.inputWithIcon]: icon,
          [styles.inputError]: error,
          [styles.inputWithEndAdornment]: endAdornment,
          [styles.chatInput]: chatInput,
        })}
        type={type}
      />
      {endAdornment}
      {error && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default React.memo(Input);
