import {LogOut} from 'react-feather';
import styles from '../UserChip.module.css';
import {Link} from 'react-router-dom';
import logoutService from '../../../../../services/HTTPService/SessionService';

export const LogoutButton: React.FC = () => {
  return (
    <Link
      to="/"
      className={styles.logoutLink}
      onClick={logoutService.logoutUser}
    >
      <LogOut className={styles.popupIcon} /> <p>Wyloguj mnie</p>
    </Link>
  );
};
