import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import LocalStorage from '../LocalStorage/LocalStorage';
import jwtDecode from 'jwt-decode';
import {DateTime} from 'luxon';
import {UserDetails} from '../../utils/interfaces/auth';
import sessionService from './SessionService';

class AxiosInstance {
  axios = axios.create({maxRedirects: 0});
  private jwt = LocalStorage.getJWT();

  constructor() {
    this.axios.interceptors.request.use(
      this.getConfigWithAuthorization,
      Promise.reject,
    );
    this.axios.interceptors.response.use(
      async response => {
        if (await sessionService.isTokenExpired(response)) {
          this.logout();
        }
        return response;
      },
      error => {
        if (error.response?.status === 401) {
          this.logout();
        }
        return Promise.reject(error);
      },
    );
  }

  private logout() {
    sessionService.logoutUser().then(() => {
      window.location.pathname = '/';
    });
  }

  private getConfigWithAuthorization = async (config: AxiosRequestConfig) => {
    const headers = {...config.headers} || {};
    try {
      headers.Authorization = `Bearer ${this.jwt}`;
    } catch (error) {
      return config;
    }
    return {...config, headers};
  };

  public assignJWT = (jwt: string) => {
    this.jwt = jwt;
    LocalStorage.setJWT(jwt);
  };

  public getUserDataFromJWT = (): UserDetails | null => {
    if (!this.jwt) {
      return null;
    }
    // @ts-ignore
    const {exp, ...userData} = jwtDecode(this.jwt);
    const endTime = DateTime.fromSeconds(exp);
    if (endTime < DateTime.now()) {
      return null;
    }
    return userData;
  };

  public get = <T = unknown>(
    ...params: [string, AxiosRequestConfig?]
  ): Promise<AxiosResponse<T>> => this.axios.get<T>(...params);

  public post = <T = unknown>(
    ...params: [string, unknown?, AxiosRequestConfig?]
  ): Promise<AxiosResponse<T>> => this.axios.post<T>(...params);

  public delete = <T = unknown>(
    ...params: [string, AxiosRequestConfig?]
  ): Promise<AxiosResponse<T>> => this.axios.delete<T>(...params);

  public patch = <T = unknown>(
    ...params: [string, unknown?, AxiosRequestConfig?]
  ): Promise<AxiosResponse<T>> => this.axios.patch<T>(...params);

  public put = <T = unknown>(
    ...params: [string, unknown?, AxiosRequestConfig?]
  ): Promise<AxiosResponse<T>> => this.axios.put<T>(...params);
}

export const getRawAxios = () => new AxiosInstance().axios;
export default AxiosInstance;
