import styles from './ReportsSubmenu.module.css';
import clsx from 'clsx';
import React from 'react';
import {TReportsTab} from '../../../../utils/reportTypes';
import {ReportSelect} from '../../../../utils/interfaces/reportSelect';

export const ReportsSubmenu: React.FC<{
  data: {
    tabTitle: string;
    items: {
      id: number;
      title: string;
      description: string;
      fields: {
        date: boolean;
        dateRange: boolean;
        month: boolean;
        week: boolean;
        selectList: ReportSelect.GROUP[];
      };
      requestUrl: string;
    }[];
  };
  icon?: React.ReactElement;
  onItemClick: (item: TReportsTab) => void;
  activeTab: number;
}> = ({data, icon, onItemClick, activeTab}) => {
  return (
    <div className={styles.submenuContainer}>
      <div className={styles.subMenuHeader}>
        <div className={styles.iconContainer}>{icon}</div>
        <div className={styles.headerText}>{data.tabTitle}</div>
      </div>
      {data.items.map(item => (
        <div
          className={clsx({
            [styles.subMenuItem]: true,
            [styles.active]: item.id === activeTab,
          })}
          onClick={() => onItemClick(item)}
          key={item.id}
        >
          <div className={styles.iconContainer}>{}</div>
          <div className={styles.itemText}>{item.title}</div>
        </div>
      ))}
    </div>
  );
};

export default ReportsSubmenu;
