import styles from './SchoolSettlementForm.module.css';
import {pl_lowercase} from '../../../utils/datePicker';
import DatePicker from 'react-multi-date-picker';
import {Button, Input} from '../../index';
import {Calendar} from 'react-feather';
import React, {useContext, useState} from 'react';
import {SchoolContext} from '../../../context/SchoolContextProvider';
import {SyncLoader} from 'react-spinners';
import HTTPService from '../../../services/HTTPService/HTTPService';

type SchoolSettlementFormProps = {
  showErrorMessage: () => void;
  showSuccessMessage: (text: string) => void;
};

export const SchoolSettlementForm = ({
  showErrorMessage,
  showSuccessMessage,
}: SchoolSettlementFormProps) => {
  const {selectedSchool, fetchSchools} = useContext(SchoolContext);
  const settlementDate = new Date();
  const [downloadingInProgress, setDownloadingInProgress] = useState(false);

  const settlementDone = selectedSchool?.settlementDone;

  const renderInputForDatePicker = (
    name: string,
    placeholder: string,
    label: string,
  ) => {
    return (
      <Input
        endAdornment={<Calendar className={styles.inputCalendar} />}
        name={name}
        type="text"
        placeholder={placeholder}
        modalInput
        label={label}
        customLabelStyle={styles.dateInputLabelOnBorder}
      />
    );
  };

  const computeSettlement = async (sendEmails: boolean) => {
    setDownloadingInProgress(true);

    try {
      const requestFunction = sendEmails
        ? HTTPService.computeSchoolSettlementAndSendEmail
        : HTTPService.computeSchoolSettlement;
      await requestFunction({
        schoolId: selectedSchool!.id,
        year: settlementDate.getFullYear(),
        month: settlementDate.getMonth() + 1,
      });
      showSuccessMessage(
        'Rozliczono placówkę' + (sendEmails ? ' i wysłano maile' : ''),
      );
      fetchSchools();
    } catch (err) {
      console.error(err);
      showErrorMessage();
    }
    setDownloadingInProgress(false);
  };

  const computeSchoolSettlement = () => computeSettlement(false);
  const computeSchoolSettlementAndSendEmail = () => computeSettlement(true);

  const renderSettlementButtons = (isSettlementDone: boolean) =>
    !isSettlementDone ? (
      <div className={styles.submitButtonContainer}>
        <Button
          className={styles.settlementButton}
          onClick={computeSchoolSettlement}
          text="Rozlicz wszystkie dzieci"
          disabled={downloadingInProgress}
        />
        <p className={styles.buttonSeparatorText}>lub</p>
        <Button
          className={styles.settlementButton}
          onClick={computeSchoolSettlementAndSendEmail}
          text="Rozlicz wszystkie dzieci i wyślij email do rodziców"
          disabled={downloadingInProgress}
        />
        {downloadingInProgress && (
          <SyncLoader className={styles.loadingIcon} size={5} />
        )}
      </div>
    ) : (
      <p className={styles.settlementDoneInfo}>Rozliczono</p>
    );

  return (
    <div className={styles.schoolSettlementContainer}>
      <h2 className={styles.title}>Rozliczenie placówki</h2>
      <p className={styles.info}>
        Rozliczenie można wykonać tylko raz w miesiącu
      </p>

      <div className={styles.monthPickerContainer}>
        <DatePicker
          locale={pl_lowercase}
          multiple={false}
          value={settlementDate}
          onlyMonthPicker
          containerClassName={styles.datePickerContainer}
          render={renderInputForDatePicker(
            'month',
            'Wybierz miesiąc',
            'Miesiąc',
          )}
          readOnly
        />
      </div>

      {settlementDone != null && renderSettlementButtons(settlementDone)}
    </div>
  );
};
