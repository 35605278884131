import styles from './SettlementPage.module.css';
import {PageTitle} from '../../components';
import React, {useState} from 'react';
import {SchoolSettlementForm} from '../../components/organisms/SchoolSettlementForm/SchoolSettlementForm';
import {ParentSettlementForm} from '../../components/organisms/ParentSettlementForm/ParentSettlementForm';
import {StatusMessage} from '../../services/HTTPService/report/types';
const STATUS_MESSAGE_DURATION_S = 5;

export const SettlementPage = () => {
  const [message, setMessage] = useState<StatusMessage | null>(null);

  const showErrorMessage = () => {
    showMessage({text: 'Wystąpił nieoczekiwany błąd', status: 'ERROR'});
  };

  const showSuccessMessage = (text: string) => {
    showMessage({text, status: 'SUCCESS'});
  };

  const showMessage = (statusMessage: StatusMessage) => {
    setMessage(statusMessage);
    setTimeout(() => setMessage(null), STATUS_MESSAGE_DURATION_S * 1000);
  };

  const getMessageTextClassName = (messageStatus: string): string =>
    styles.statusMessage +
    ' ' +
    (messageStatus == 'ERROR' ? styles.errorMessage : styles.successMessage);

  return (
    <div>
      <PageTitle title="Rozliczenia" />
      <div className={styles.settlementScreenContainer}>
        <div className={styles.settlementContainer}>
          <SchoolSettlementForm
            showErrorMessage={showErrorMessage}
            showSuccessMessage={showSuccessMessage}
          />
          <ParentSettlementForm
            showErrorMessage={showErrorMessage}
            showSuccessMessage={showSuccessMessage}
          />
        </div>
        <div className={styles.messageContainer}>
          {message && (
            <p className={getMessageTextClassName(message.status)}>
              {message.text}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
