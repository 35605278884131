import styles from './ParentSettlementForm.module.css';
import {pl_lowercase} from '../../../utils/datePicker';
import DatePicker from 'react-multi-date-picker';
import {Button, Input} from '../../index';
import {Calendar} from 'react-feather';
import React, {useContext, useEffect, useState} from 'react';
import {SchoolContext} from '../../../context/SchoolContextProvider';
import HTTPService from '../../../services/HTTPService/HTTPService';
import {Parent} from '../../../utils/interfaces/objects/Parent';
import Autocomplete from '../../shared/Select/Autocomplete';
import {ReportsSelectOption} from '../../../utils/interfaces/reportSelect';
import {sortedByName} from '../../../utils/functions/sorting';
import {SyncLoader} from 'react-spinners';

type ParentSettlementFormProps = {
  showErrorMessage: () => void;
  showSuccessMessage: (text: string) => void;
};

export const ParentSettlementForm = ({
  showErrorMessage,
  showSuccessMessage,
}: ParentSettlementFormProps) => {
  const {selectedSchool} = useContext(SchoolContext);
  const [parents, setParents] = useState<ReportsSelectOption[]>([]);
  const settlementDate = new Date();
  const [selectedParent, setSelectedParent] = useState<
    ReportsSelectOption | undefined
  >();
  const [downloadingInProgress, setDownloadingInProgress] = useState(false);

  const settlementDone = selectedSchool?.settlementDone;

  useEffect(() => {
    const prepareParents = (parentsToPrepare: Parent[]) => {
      const parentOptions = parentsToPrepare.map(parent => ({
        value: parent.id.toString(),
        name: `${parent.lastname} ${parent.firstname}`,
      }));
      sortedByName(parentOptions);
      return parentOptions;
    };

    const fetchParents = () => {
      const groupsInSchool = selectedSchool?.groups || [];
      const groupIds = groupsInSchool.map(group => group.id);

      HTTPService.getParentForSchool(groupIds)
        .then(res => {
          const {data: newParents} = res;
          setParents(prepareParents(newParents));
        })
        .catch(err => {
          console.error(err);
          setParents([]);
        });
    };
    fetchParents();
    setSelectedParent(undefined);
  }, [selectedSchool]);

  const renderInputForDatePicker = (
    name: string,
    placeholder: string,
    label: string,
  ) => {
    return (
      <Input
        endAdornment={<Calendar className={styles.inputCalendar} />}
        name={name}
        type="text"
        placeholder={placeholder}
        modalInput
        label={label}
        customLabelStyle={styles.dateInputLabelOnBorder}
      />
    );
  };

  const recomputeParentSettlement = async () => {
    if (!selectedParent?.value || !selectedSchool?.id) {
      return;
    }

    setDownloadingInProgress(true);

    try {
      await HTTPService.recomputeParentSettlement({
        schoolId: selectedSchool.id,
        parentId: +selectedParent!.value,
        year: settlementDate.getFullYear(),
        month: settlementDate.getMonth() + 1,
      });
      showSuccessMessage('Ponownie rozliczono rodzica');
    } catch (err) {
      console.error(err);
      showErrorMessage();
    }
    setDownloadingInProgress(false);
  };

  const handleParentChange = (parentOption: ReportsSelectOption) =>
    setSelectedParent(parentOption);

  const renderSettlementButton = (isSettlementDone: boolean) =>
    isSettlementDone ? (
      <div className={styles.submitButtonContainer}>
        <Button
          className={styles.settlementButton}
          onClick={recomputeParentSettlement}
          text="Przelicz ponownie konto rodzica i wyślij email"
          disabled={!selectedParent?.value || downloadingInProgress}
        />

        {downloadingInProgress && (
          <SyncLoader className={styles.loadingIcon} size={5} />
        )}
      </div>
    ) : (
      <p className={styles.settlementDoneInfo}>
        Najpierw wykonaj rozliczenie placówki
      </p>
    );

  return (
    <div className={styles.parentSettlementContainer}>
      <h2 className={styles.title}>Ponowne rozliczenie rodzica</h2>
      <div className={styles.parentPickerContainer}>
        <Autocomplete
          options={parents}
          onChange={handleParentChange}
          value={selectedParent}
          name={selectedParent?.name || ''}
          label={'Rodzic'}
        />
      </div>
      <div className={styles.monthPickerContainer}>
        <DatePicker
          locale={pl_lowercase}
          multiple={false}
          value={settlementDate}
          onlyMonthPicker
          containerClassName={styles.datePickerContainer}
          render={renderInputForDatePicker(
            'month',
            'Wybierz miesiąc',
            'Miesiąc',
          )}
          readOnly
        />
      </div>

      {settlementDone != null && renderSettlementButton(settlementDone)}
    </div>
  );
};
