import ReportsSubmenu from '../ReportsSubmenu/ReportsSubmenu';
import styles from './ReportsMenu.module.css';
import {TReportsTab} from '../../../../utils/reportTypes';
import React from 'react';
import {ROLE_REPORT_TYPE} from '../ReportTypes';
import {UserDetails} from '../../../../utils/interfaces/auth';

export const ReportsMenu: React.FC<{
  title: string;
  description?: string;
  onItemClick: (item: TReportsTab) => void;
  activeTab: number;
  userDetails?: UserDetails;
}> = ({title, description, onItemClick, activeTab, userDetails}) => {
  const userReportType = ROLE_REPORT_TYPE.find(
    reportType => reportType.role === userDetails?.role,
  );
  return (
    <div className={styles.menuContainer}>
      <div className={styles.headerContainer}>
        <h2 className={styles.header}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      {userReportType?.reportData.map(reportData => (
        <ReportsSubmenu
          key={reportData.data.tabTitle}
          data={reportData.data}
          icon={reportData.icon}
          onItemClick={onItemClick}
          activeTab={activeTab}
        />
      ))}
    </div>
  );
};

export default ReportsMenu;
