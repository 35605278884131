import {getRawAxios} from '../HTTPService/AxiosInstance';
import {reportFormTypes} from '../../utils/reportFormTypes';
import {ReportSelect} from '../../utils/interfaces/reportSelect';

const DOWNLOAD_FAILED_MESSAGE = 'Wystąpił bład';

class ReportRequestService {
  private axios = getRawAxios();

  public downloadReport(
    requestUrl: string,
    urlParams: URLSearchParams,
    setDownloadingInProgress: (state: boolean) => void,
    pushErrorMessage: (message: string) => void,
  ) {
    if (requestUrl.includes('/export/file')) {
      const [url, requestData] = this.generateUrlAndDataForNodeRequest(
        requestUrl,
        urlParams,
      );
      this.performFileDownload(
        url,
        setDownloadingInProgress,
        pushErrorMessage,
        requestData,
      );
    } else {
      // inconsistency - node treats group_id=0 as all groups - spring treats lack of groupIds param as all
      if (urlParams.get(reportFormTypes[ReportSelect.GROUP].paramName) == '0') {
        urlParams.delete(reportFormTypes[ReportSelect.GROUP].paramName);
      }
      const urlWithParams = `${requestUrl}?${urlParams.toString()}`;
      this.performFileDownload(
        urlWithParams,
        setDownloadingInProgress,
        pushErrorMessage,
      );
    }
  }
  private performFileDownload = (
    urlWithParams: string,
    setLoadingInProgress: (x: boolean) => void,
    pushErrorMessage: (message: string) => void,
    dataForNodeRequest = '',
  ) => {
    setLoadingInProgress(true);
    this.axios(urlWithParams, {
      method: dataForNodeRequest ? 'POST' : 'GET',
      data: dataForNodeRequest,
      responseType: 'blob',
    })
      .then(response => {
        if (!response.headers['content-disposition']) {
          return;
        }

        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        const fileName = decodeURI(
          response.headers['content-disposition'].split('filename=')[1],
        );

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(() => pushErrorMessage(DOWNLOAD_FAILED_MESSAGE))
      .finally(() => {
        setLoadingInProgress(false);
      });
  };
  private adjustUrlParamsToNode = (urlParams: URLSearchParams) => {
    const keyValueParamArray = urlParams.toString().split(/&|=/);
    const nodeUrlParams = new URLSearchParams();
    let yearVal, monthVal;

    for (let i = 0; i < keyValueParamArray.length - 1; i += 2) {
      const key = keyValueParamArray[i];
      const value = keyValueParamArray[i + 1];
      switch (key) {
        case 'schoolId':
          nodeUrlParams.append('school_id', value);
          break;
        case 'groupIds':
          nodeUrlParams.append('group_id', value);
          break;
        case 'subjectId':
          nodeUrlParams.append('lesson_id', value);
          break;
        case 'parentId':
          nodeUrlParams.append('parent', value);
          break;
        case 'fromDate':
          nodeUrlParams.append('start_date', value);
          break;
        case 'toDate':
          nodeUrlParams.append('end_date', value);
          break;
        case 'year':
          yearVal = value;
          break;
        case 'month':
          monthVal = value;
          break;
        case 'advanced_present_report':
          nodeUrlParams.append('type', '1');
          break;
        case 'advanced_dinner_report':
          nodeUrlParams.append('type', '4');
          break;
        default:
          nodeUrlParams.append(key, value);
      }
    }
    if (yearVal) {
      if (monthVal) {
        nodeUrlParams.append('month_selection', yearVal + '-' + monthVal);
      } else {
        nodeUrlParams.append('year_selection', yearVal);
      }
    }

    return nodeUrlParams.toString() ? '&' + nodeUrlParams.toString() : '';
  };

  generateUrlAndDataForNodeRequest = (
    url: string,
    urlParams: URLSearchParams,
  ) => {
    const nodeUrlParams = this.adjustUrlParamsToNode(urlParams);
    const typeKey = 'type=';
    if (url.indexOf(typeKey) == -1)
      throw Error("Podaj parametr 'type' z wartoscia z backendu w nodzie");
    const typeValueIndex = url.indexOf(typeKey) + typeKey.length;
    const typePair = nodeUrlParams.includes('type')
      ? ''
      : url.substring(url.indexOf(typeKey)); // assume it is a number (type is the last part of url)

    return [
      url.substring(0, typeValueIndex - typeKey.length - 1),
      `${typePair}${nodeUrlParams}`,
    ];
  };
}

export default new ReportRequestService();
