import {Role} from '../../../utils/interfaces/auth';
import {GitHub, DollarSign, BarChart2, Users, BookOpen} from 'react-feather';
import {
  CHILDREN_TABS,
  STATS_TABS,
  SETTLEMENTS_TABS,
  PARENTS_TABS,
  JOURNAL_TABS,
} from '../../../utils/reportsTabs';
import React from 'react';
type RoleReportType = {
  role: Role;
  reportData: ReportData[];
};
type ReportData = {
  icon: React.ReactElement;
  data: any;
};
export const ROLE_REPORT_TYPE: RoleReportType[] = [
  {
    role: 'administrator',
    reportData: [
      {
        icon: <GitHub />,
        data: CHILDREN_TABS,
      },
      {
        icon: <Users />,
        data: PARENTS_TABS,
      },
      {
        icon: <DollarSign />,
        data: SETTLEMENTS_TABS,
      },
      {
        icon: <BookOpen />,
        data: JOURNAL_TABS,
      },
    ],
  },
  {
    role: 'opiekun',
    reportData: [
      {
        icon: <GitHub />,
        data: CHILDREN_TABS,
      },
      {
        icon: <Users />,
        data: PARENTS_TABS,
      },
      {
        icon: <DollarSign />,
        data: SETTLEMENTS_TABS,
      },
      {
        icon: <BookOpen />,
        data: JOURNAL_TABS,
      },
    ],
  },
  {
    role: 'pracownik',
    reportData: [
      {
        icon: <GitHub />,
        data: CHILDREN_TABS,
      },
      {
        icon: <Users />,
        data: PARENTS_TABS,
      },
      {
        icon: <DollarSign />,
        data: SETTLEMENTS_TABS,
      },
      {
        icon: <BookOpen />,
        data: JOURNAL_TABS,
      },
    ],
  },
  {
    role: 'urzad',
    reportData: [
      {
        icon: <BarChart2 />,
        data: STATS_TABS,
      },
    ],
  },
];
