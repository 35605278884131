import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Layout from './components/Layout/Layout';
import ReportsPage from './pages/ReportsPage/ReportsPage';
import {store} from './redux/store';
import {Provider} from 'react-redux';
import LoginPage from './pages/LoginPage/LoginPage';
import RouterProxy from './components/shared/RouterProxy/RouterProxy';
import {CanteenPage} from './pages/CanteenPage/CanteenPage';
import {SettlementPage} from './pages/SettlementPage/SettlementPage';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route element={<Layout />}>
            <Route path="/pulpit/raporty" element={<ReportsPage />} />
            <Route path="/pulpit/stolowka" element={<CanteenPage />} />
            <Route path="/pulpit/rozliczenia" element={<SettlementPage />} />
            <Route path="/urzad/raporty" element={<ReportsPage />} />
            <Route path="*" element={<RouterProxy />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
