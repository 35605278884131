import {PageTitle} from '../../components';
import React from 'react';
import {CanteenStatusList} from '../../components/organisms/CanteenStatusList/CanteenStatusList';

export const CanteenPage = () => {
  return (
    <div>
      <PageTitle title="Stołówka" />
      <CanteenStatusList />
    </div>
  );
};
