import {ReportSelect, ReportsSelectOption} from './interfaces/reportSelect';
import {ReportSwitch} from './interfaces/reportSwitch';

export const PROMPT_VALUE = ' ';
export const REQUIRED = 'REQUIRED';
export const OMIT_PARAM = 'OMIT_PARAM';
export const DEFAULT_ALL = '0';
export const DEFAULT = 'default';

export const reportFormTypes: Record<ReportSelect, IReportFormType> = {
  [ReportSelect.SCHOOL_WITH_ALL]: {
    label: 'Placówka',
    defaultValue: {value: PROMPT_VALUE, name: 'Wybierz placówkę'},
    paramName: 'schoolId',
  },
  [ReportSelect.GROUP]: {
    label: 'Grupa',
    defaultValue: {value: DEFAULT_ALL, name: 'Wszystkie'},
    paramName: 'groupIds',
  },
  [ReportSelect.PARENT]: {
    label: 'Rodzic',
    defaultValue: {value: OMIT_PARAM, name: 'Wszyscy'},
    paramName: 'parentId',
  },
  [ReportSelect.SOCIAL_ASSISTANCE]: {
    label: 'Ośrodek pomocy społecznej',
    defaultValue: {value: PROMPT_VALUE, name: 'Wybierz ośrodek'},
    paramName: 'socialCenterName',
  },
  [ReportSelect.FEE_REPORT_TYPE]: {
    label: 'Typ raportu',
    defaultValue: {value: 'default', name: 'Ogólny'},
    paramName: 'type',
  },
  [ReportSelect.JOURNAL]: {
    label: 'Dziennik',
    defaultValue: {value: REQUIRED, name: 'Wybierz dziennik'},
    paramName: 'journal',
  },
};

export const reportFormSwitches: Record<ReportSwitch, IReportFormSwitch> = {
  [ReportSwitch.DETAILED_HOURLY_REPORT]: {
    label: 'Szczegółowy raport godzinowy',
    value: '1',
    paramName: 'advanced_present_report',
  },
  [ReportSwitch.DETAILED_MEALS_REPORT]: {
    label: 'Szczegółowy raport obiadów',
    value: '1',
    paramName: 'advanced_dinner_report',
  },
  [ReportSwitch.WITH_BALANCES]: {
    label: '+ Stan konta (wybierz przed wysłaniem rozliczenia)',
    value: 'true',
    paramName: 'withBalances',
  },
};

export interface IReportFormType {
  label: string;
  defaultValue: ReportsSelectOption;
  paramName: string;
}

export interface IReportFormSwitch {
  label: string;
  value: string;
  paramName: string;
}
