import {UserDetails} from '../../utils/interfaces/auth';

const ACCESS_JWT_KEY = '#access_jwt_token';
const USER_DETAILS_KEY = '#user_details';

export default {
  setJWT: (jwt: string) => localStorage.setItem(ACCESS_JWT_KEY, jwt),
  getJWT: () => localStorage.getItem(ACCESS_JWT_KEY),
  setUserDetails: (userDetails: UserDetails) =>
    localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(userDetails)),
  getUserDetails: () => localStorage.getItem(USER_DETAILS_KEY),
  clear: () => localStorage.clear(),
};
