import {createContext, ReactNode, useEffect, useState} from 'react';
import {School} from '../utils/interfaces/objects/School';
import {useUserDetails} from '../hooks/useUserDetails';
import HTTPService from '../services/HTTPService/HTTPService';

export type SchoolContextType = {
  selectedSchool?: School;
  changeSchool: (schoolId: number) => void;
  schools: School[];
  setSchools: (schools: School[]) => void;
  fetchSchools: () => void;
};

const SchoolContext = createContext<SchoolContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeSchool: () => {},
  schools: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSchools: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchSchools: () => {},
});

const SchoolContextProvider: React.FC<{
  children: ReactNode;
}> = ({children}) => {
  const [schools, setSchools] = useState<School[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<School>();
  const user = useUserDetails();

  const changeSchool = (schoolId: number) => {
    const newSchool = schools.filter(school => school.id === schoolId)[0];
    if (newSchool) {
      setSelectedSchool(newSchool);
    }
  };

  const fetchSchools = () => {
    HTTPService.fetchSchools()
      .then(res => {
        setSchools(res.data);
        if (res.data?.length) {
          setSelectedSchool(res.data[0]);
        }
      })
      .catch(err => console.warn(err));
  };

  useEffect(() => {
    if (user) {
      fetchSchools();
    }
  }, [user]);

  return (
    <SchoolContext.Provider
      value={{
        selectedSchool: selectedSchool,
        changeSchool: changeSchool,
        schools: schools,
        setSchools: setSchools,
        fetchSchools: fetchSchools,
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
};

export {SchoolContext, SchoolContextProvider};
