import styles from './UserBalance.module.css';
import {PlusCircle} from 'react-feather';
import {userBalance} from '../../../utils/mockData';
export const UserBalance: React.FC = ({}) => {
  return (
    <div className={styles.balanceWrapper}>
      <PlusCircle />
      <span>
        Stan konta ogólny:<strong> {userBalance.main_balance} zł</strong>
      </span>
      <span>
        Stan konta (pobyt): <strong> {userBalance.stay_balance} zł</strong>
      </span>
      <span>
        Stan konta (posiłki):{' '}
        <strong>{userBalance.meal_balance} -33.00zł</strong>
      </span>
    </div>
  );
};

export default UserBalance;
