import {ExtensionContent, SidebarExtensionTab} from './interfaces/sidebar';
import {
  BookOpen,
  Calendar,
  CornerRightDown,
  Layers,
  UserCheck,
  Watch,
  Table,
} from 'react-feather';
import {UserDetails} from './interfaces/auth';
import {PL_TAX_SETTLEMENT_GENERATE} from '../consts/permissions';

const showSettlementTab = (user?: UserDetails) => {
  return (
    user?.permissions?.includes(PL_TAX_SETTLEMENT_GENERATE) ||
    user?.role == 'administrator' ||
    user?.role == 'opiekun'
  );
};

/* eslint-disable  @typescript-eslint/no-unused-vars */
export const getStaffExtensionTabs = (
  user?: UserDetails,
): SidebarExtensionTab[] => [
  {
    to: '/pulpit/pracownicy',
    text: 'Pracownicy',
    icon: UserCheck,
  },
  {
    to: '/pulpit/grafik',
    text: 'Grafik',
    icon: BookOpen,
  },
  {
    to: '/pulpit/rcp',
    text: 'RCP',
    icon: Watch,
  },
];

export const getAdministratorExtensionTabs = (
  user?: UserDetails,
): SidebarExtensionTab[] => [
  {
    to: '/pulpit/kwitariusz',
    text: 'Kwitariusz',
    icon: BookOpen,
  },
  {
    to: '/pulpit/obecnosci',
    text: 'Lista obecności',
    icon: Calendar,
  },
  {
    to: '/pulpit/import',
    text: 'Import i eksport',
    icon: CornerRightDown,
  },
  {
    to: '/pulpit/raporty',
    text: 'Raporty',
    icon: Layers,
  },
  {
    to: '/pulpit/rozliczenia',
    text: 'Rozliczenia',
    icon: Table,
    hidden: !showSettlementTab(user),
  },
];

const getAllSidebarExtensionTabsForContent = (
  content: ExtensionContent,
  user?: UserDetails,
): SidebarExtensionTab[] => {
  switch (content) {
    case 'administration':
      return getAdministratorExtensionTabs(user);
    case 'staff':
      return getStaffExtensionTabs(user);
    default:
      return [];
  }
};

export const getSidebarExtensionTabsForContent = (
  content: ExtensionContent,
  user?: UserDetails,
): SidebarExtensionTab[] => {
  const allTabs = getAllSidebarExtensionTabsForContent(content, user);
  return allTabs.filter(tab => !tab.hidden);
};
