import styles from './ReportsForm.module.css';
import {SelectChangeEvent, Switch} from '@mui/material';
import {reportFormSwitches} from '../../../../utils/reportFormTypes';
import React from 'react';
import {ReportSwitch} from '../../../../utils/interfaces/reportSwitch';
import {TReportSwitch} from '../../../../utils/reportTypes';

interface ReportsSwitchesProps {
  switchList: ReportSwitch[];
  switchedValue: TReportSwitch;
  setSwitchedValue: (value: TReportSwitch) => void;
}

const ReportsSwitches: React.FC<ReportsSwitchesProps> = ({
  switchList,
  switchedValue,
  setSwitchedValue,
}) => {
  const handleChange = (e: SelectChangeEvent) => {
    if (switchedValue.hasOwnProperty(e.target.name)) {
      setSwitchedValue({
        ...switchedValue,
        [e.target.name]: !switchedValue[e.target.name as keyof TReportSwitch],
      });
    }
  };

  return (
    <div className={styles.switchList}>
      {switchList &&
        switchList?.map(switchEl => (
          <div key={switchEl} className={styles.switchContainer}>
            <Switch
              name={switchEl}
              classes={{
                ...(switchedValue[switchEl] && {
                  track: styles.switchTrack,
                  thumb: styles.switchThumb,
                }),
              }}
              onChange={handleChange}
              checked={switchedValue[switchEl]}
              size="medium"
            />
            <p className={styles.switchLabel}>
              {reportFormSwitches[switchEl].label}
            </p>
          </div>
        ))}
    </div>
  );
};

export default ReportsSwitches;
