import styles from '../ReportsForm/ReportsForm.module.css';
import {
  IReportFormType,
  reportFormTypes,
} from '../../../../utils/reportFormTypes';
import React from 'react';
import {
  ReportSelect,
  ReportsSelectOption,
} from '../../../../utils/interfaces/reportSelect';
import Autocomplete from '../../Select/Autocomplete';

interface ReportsSelectProps {
  value: ReportsSelectOption;
  handleChange: (selectType: ReportSelect, value: ReportsSelectOption) => void;
  disabled: boolean;
  selectType: ReportSelect;
  selectOptions: ReportsSelectOption[];
}

const ReportsSelect: React.FC<ReportsSelectProps> = ({
  value,
  handleChange,
  disabled,
  selectType,
  selectOptions,
}) => {
  const renderLabel = (formTypes: Record<string, IReportFormType>) => {
    return formTypes[selectType].label;
  };

  return (
    <div className={styles.selectContainer}>
      <Autocomplete
        value={value}
        name={selectType}
        disabled={disabled}
        options={selectOptions}
        onChange={val => handleChange(selectType, val)}
        label={renderLabel(reportFormTypes)}
      />
    </div>
  );
};

export default ReportsSelect;
