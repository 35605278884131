import React, {useState} from 'react';
import {Modal} from '@mui/material';
import styles from './LoginPage.module.css';
import Input from '../../components/shared/Input/Input';
import HTTPService from '../../services/HTTPService/HTTPService';
import {logNetworkError} from '../../utils/error';

interface ResetPasswordModalProps {
  hidden: boolean;
  close: () => void;
}

export const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  hidden,
  close,
}) => {
  const [email, setEmail] = useState('');
  const [displayError, setDisplayError] = useState('');
  const [displaySuccess, setDisplaySuccess] = useState('');
  const [enable, setEnable] = useState(true);

  const isValidEmail = (emailArg: string) => {
    return /\S+@\S+\.\S+/.test(emailArg);
  };

  const onSubmit = () => {
    setEnable(false);
    setTimeout(() => setEnable(true), 5000);
    HTTPService.resetPassword({email: email})
      .then(() => {
        setEmail('');
        setDisplaySuccess('Hasło zostało zresetowane');
      })
      .catch(error => {
        logNetworkError(error);
        setDisplaySuccess('');
        setDisplayError('Niepowodzenie, spróbuj ponownie później');
      });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isValidEmail(e.target.value)) {
      setDisplaySuccess('');
      setDisplayError('Email jest niepoprawny');
      setEnable(false);
    } else {
      setDisplaySuccess('');
      setDisplayError('');
      setEnable(true);
    }
    setEmail(e.target.value);
  };
  const onClose = () => {
    setDisplaySuccess('');
    setDisplayError('');
    setEmail('');
    close();
  };
  return (
    <Modal open={!hidden} onClose={onClose}>
      <div className={styles.modalContent}>
        <h1 className={styles.loginFormTitle}>Odzyskiwanie hasła</h1>
        <div className={styles.subHeader}>
          Wprowadź poniżej swój adres e-mail, wyślemy Ci kolejne instrukcje jak
          zresetować Twoje hasło.
        </div>
        <Input
          onChange={handleChange}
          value={email}
          placeholder="Wprowadz adres email"
          name="email"
          label="Adres e-mail"
          type={'text'}
          errorMessage={'Wpisz email'}
        />
        <div
          className={`${styles.displayMessage} ${
            displayError && styles.errorMessage
          } ${displaySuccess && styles.successMessage} `}
        >
          {displayError} {displaySuccess}
        </div>
        <button
          className={`${styles.submitBtn}  ${
            !enable && styles.submitBtnDisabled
          }`}
          type="submit"
          disabled={!enable}
          onClick={onSubmit}
        >
          Resetuj hasło
        </button>
      </div>
    </Modal>
  );
};
