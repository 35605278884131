import {GridColDef} from '@mui/x-data-grid';
import {Switch} from '@mui/material';

export const columns = (onChange: any): GridColDef[] => [
  {field: 'firstname', headerName: 'Imię', flex: 1},
  {field: 'lastname', headerName: 'Nazwisko', flex: 1},
  {
    field: 'excuseAbsence',
    headerName: 'USPRAWIEDLIWIONA NIEOBECNOŚĆ',
    flex: 1,
    renderCell: params => {
      return <p>{params.row.excuseAbsence ? 'TAK' : 'NIE'}</p>;
    },
  },
  {
    field: 'paymentOverdue',
    headerName: 'Zaległa płatność',
    flex: 0.5,
    renderCell: params => {
      return (
        <Switch
          defaultChecked={params.row.paymentOverdue}
          onChange={() =>
            onChange({
              childId: params.row.childId,
              value: !params.row.paymentOverdue,
            })
          }
        />
      );
    },
  },
];
