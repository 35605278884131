import {Role} from './interfaces/auth';
import {TReportsTab, TReportTypeData} from './reportTypes';
import {ReportSelect} from './interfaces/reportSelect';
import {
  GET_ALL_DAYS,
  GET_AVERAGE_CHILDREN_PER_HOUR,
  GET_CHILD_ABSENCES,
  GET_CHILD_ABSENCES_COUNT,
  GET_CHILD_DAILY_PRESENCE,
  GET_CHILD_TIME_PRESENCE,
  GET_CHILDREN_FEES,
  GET_CHILDREN_MONTHLY_SETTLEMENT,
  GET_CHILDREN_WITH_KDR,
  GET_CHILDREN_WITH_KDR_FEES,
  GET_DIAGNOSES,
  GET_DISABLED_CHILDREN,
  GET_DISABLED_CHILDREN_FEES,
  GET_DRAFT_DAY_PLAN,
  GET_EMPLOYEE_WORKING_TIME_SETTLEMENT,
  GET_IMPORTANT_EVENTS,
  GET_LESS_THAN_5_ABSENCES,
  GET_MEALS_REPORT,
  GET_MONTH_PLANS,
  GET_MONTHLY_MEALS_SETTLEMENT,
  GET_MONTHLY_PRESENCE_REPORT,
  GET_MONTHLY_SETTLEMENT,
  GET_MONTHLY_STAY_SETTLEMENT,
  GET_PARENT_CONSULTATIONS,
  GET_PARENT_DEBTS,
  GET_PARENT_MEETINGS,
  GET_PARENT_PAYMENTS,
  GET_PARENT_REFUNDS,
  GET_PSYCHOLOGICAL_HELP,
  GET_SOCIAL_ASSISTANCE,
  GET_TIMESHEET_HOUR_PERIODS_REPORT,
  GET_TRIPS,
} from '../services/HTTPService/report/endpoints';
import {ReportSwitch} from './interfaces/reportSwitch';

const DEFAULT_DATE_DESCRIPTION = 'Wybierz dzień, którego ma dotyczyć raport';
const DEFAULT_MONTH_DESCRIPTION = 'Wybierz miesiąc, którego ma dotyczyć raport';
const DEFAULT_DATE_RANGE_DESCRIPTION =
  'Wybierz zakres dat, którego ma dotyczyć raport';
const DEFAULT_DESCRIPTION = 'Wybierz dane, których ma dotyczyć raport';

export const CHILDREN_TABS: TReportTypeData = {
  tabTitle: 'Dzieci',
  items: [
    {
      id: 1,
      title: 'Dzienny raport obecności',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: true,
        dateRange: false,
        month: false,
        selectList: [ReportSelect.GROUP],
      },
      requestUrl: GET_CHILD_DAILY_PRESENCE,
    },
    {
      id: 2,
      title: 'Miesięczny raport obecności',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP],
        switchList: [ReportSwitch.DETAILED_HOURLY_REPORT],
      },
      requestUrl: GET_MONTHLY_PRESENCE_REPORT,
    },
    {
      id: 3,
      title: 'Czasowy raport obecności',
      description: DEFAULT_DATE_RANGE_DESCRIPTION,
      fields: {
        date: false,
        dateRange: true,
        month: false,
        selectList: [ReportSelect.GROUP],
      },
      requestUrl: GET_CHILD_TIME_PRESENCE,
    },
    {
      id: 4,
      title: 'Raport nieobecności',
      description: DEFAULT_DATE_RANGE_DESCRIPTION,
      fields: {
        date: false,
        dateRange: true,
        month: false,
        selectList: [],
      },
      requestUrl: GET_CHILD_ABSENCES,
    },
    {
      id: 5,
      title: 'Raport liczby zgłoszonych nieobecności',
      description: DEFAULT_DATE_RANGE_DESCRIPTION,
      fields: {
        date: false,
        dateRange: true,
        month: false,
        selectList: [],
      },
      requestUrl: GET_CHILD_ABSENCES_COUNT,
    },
    {
      id: 6,
      title: 'Mniej niż 5 obecności',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [],
      },
      requestUrl: GET_LESS_THAN_5_ABSENCES,
    },
    {
      id: 7,
      title: 'Posiłki',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP],
        switchList: [ReportSwitch.DETAILED_MEALS_REPORT],
      },
      requestUrl: GET_MEALS_REPORT,
    },
    {
      id: 8,
      title: 'Średnia liczba dzieci na godzinę',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP],
      },
      requestUrl: GET_AVERAGE_CHILDREN_PER_HOUR,
    },
    {
      id: 9,
      title: 'Dzieci z orzeczeniem',
      description: 'Wygeneruj raport dzieci z orzeczeniem',
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [],
      },
      requestUrl: GET_DISABLED_CHILDREN,
    },
    {
      id: 10,
      title: 'Dzieci z KDR',
      description: 'Wygeneruje raport dzieci z KDR',
      fields: {
        date: false,
        dateRange: true,
        month: false,
        selectList: [],
      },
      requestUrl: GET_CHILDREN_WITH_KDR,
    },
  ],
};
export const PARENTS_TABS: TReportTypeData = {
  tabTitle: 'Rodzice',
  items: [
    {
      id: 11,
      title: 'Zestawienie sald (kwitki)',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP],
      },
      requestUrl: GET_MONTHLY_SETTLEMENT,
    },
    {
      id: 12,
      title: 'Zestawienie opłat - pobyt',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP],
      },
      requestUrl: GET_MONTHLY_STAY_SETTLEMENT,
    },
    {
      id: 13,
      title: 'Zestawienie opłat - posiłki',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP],
      },
      requestUrl: GET_MONTHLY_MEALS_SETTLEMENT,
    },
    {
      id: 14,
      title: 'Wpłaty rodziców',
      description: DEFAULT_DATE_RANGE_DESCRIPTION,
      fields: {
        date: false,
        dateRange: true,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.PARENT],
      },
      requestUrl: GET_PARENT_PAYMENTS,
    },
    {
      id: 15,
      title: 'Zwroty rodziców',
      description: DEFAULT_DATE_RANGE_DESCRIPTION,
      fields: {
        date: false,
        dateRange: true,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.PARENT],
      },
      requestUrl: GET_PARENT_REFUNDS,
    },
    {
      id: 16,
      title: 'Zadłużenie rodziców',
      description: 'Wybierz typ raportu',
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [
          ReportSelect.GROUP,
          ReportSelect.FEE_REPORT_TYPE,
          ReportSelect.PARENT,
        ],
      },
      requestUrl: GET_PARENT_DEBTS,
    },
  ],
};
export const SETTLEMENTS_TABS: TReportTypeData = {
  tabTitle: 'Rozliczenia',
  items: [
    {
      id: 17,
      title: 'Koszty z podziałem na dzieci',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [],
        switchList: [ReportSwitch.WITH_BALANCES],
      },
      requestUrl: GET_CHILDREN_FEES,
    },
    {
      id: 18,
      title: 'Koszty dzieci z orzeczeniem',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [],
      },
      requestUrl: GET_DISABLED_CHILDREN_FEES,
    },
    {
      id: 19,
      title: 'Koszty dzieci z KDR',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [],
      },
      requestUrl: GET_CHILDREN_WITH_KDR_FEES,
    },
    {
      id: 20,
      title: 'Pomoc społeczna',
      description: 'Wybierz ośrodek pomoct społecznej',
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.SOCIAL_ASSISTANCE],
      },
      requestUrl: GET_SOCIAL_ASSISTANCE,
    },
    {
      id: 21,
      title: 'Rozliczenia dzieci',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [],
      },
      requestUrl: GET_CHILDREN_MONTHLY_SETTLEMENT,
    },
    {
      id: 22,
      title: 'Rozliczenie czasu pracy',
      description: DEFAULT_MONTH_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [],
      },
      requestUrl: GET_EMPLOYEE_WORKING_TIME_SETTLEMENT,
    },
  ],
};

export const JOURNAL_TABS: TReportTypeData = {
  tabTitle: 'E-Dziennik',
  items: [
    {
      id: 23,
      title: 'Wszystkie dni',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_ALL_DAYS,
    },
    {
      id: 24,
      title: 'Wszystkie dni - miesięczny',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_ALL_DAYS,
    },
    {
      id: 25,
      title: 'Ogólny plan miesięczny',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_MONTH_PLANS,
    },
    {
      id: 26,
      title: 'Ogólny plan miesięczny - miesięczny',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_MONTH_PLANS,
    },
    {
      id: 27,
      title: 'Ramowy plan dnia',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_DRAFT_DAY_PLAN,
    },
    {
      id: 29,
      title: 'Wycieczki',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_TRIPS,
    },
    {
      id: 30,
      title: 'Wycieczki - miesięczny',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_TRIPS,
    },
    {
      id: 31,
      title: 'Zebrania z rodzicami',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_PARENT_MEETINGS,
    },
    {
      id: 32,
      title: 'Zebrania z rodzicami - miesięczny',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_PARENT_MEETINGS,
    },
    {
      id: 33,
      title: 'Pomoc psychologiczna',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_PSYCHOLOGICAL_HELP,
    },
    {
      id: 34,
      title: 'Pomoc psychologiczna - miesięczny',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_PSYCHOLOGICAL_HELP,
    },
    {
      id: 35,
      title: 'Diagnozy',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_DIAGNOSES,
    },
    {
      id: 36,
      title: 'Diagnozy - miesięczny',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_DIAGNOSES,
    },
    {
      id: 37,
      title: 'Konsultacje z rodzicami',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_PARENT_CONSULTATIONS,
    },
    {
      id: 38,
      title: 'Konsultacje z rodzicami - miesięczny',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_PARENT_CONSULTATIONS,
    },
    {
      id: 39,
      title: 'Ważne wydarzenia',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: false,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_IMPORTANT_EVENTS,
    },
    {
      id: 40,
      title: 'Ważne wydarzenia - miesięczny',
      description: DEFAULT_DESCRIPTION,
      fields: {
        date: false,
        dateRange: false,
        month: true,
        selectList: [ReportSelect.GROUP, ReportSelect.JOURNAL],
      },
      requestUrl: GET_IMPORTANT_EVENTS,
    },
  ],
};

export const STATS_TABS: TReportTypeData = {
  tabTitle: 'Statystyczne',
  items: [
    {
      id: 99,
      title: 'Średnia liczba dzieci',
      description: DEFAULT_DATE_DESCRIPTION,
      fields: {
        date: false,
        dateRange: true,
        month: false,
        selectList: [ReportSelect.SCHOOL_WITH_ALL],
      },
      requestUrl: GET_TIMESHEET_HOUR_PERIODS_REPORT,
    },
  ],
};

export const getDefaultReportForUserRole = (
  userRole: Role | undefined,
): TReportsTab => {
  switch (userRole) {
    case 'urzad':
      return STATS_TABS.items[0];
    case 'opiekun':
    case 'pracownik':
    case 'administrator':
    case 'rodzic':
    default:
      return CHILDREN_TABS.items[0];
  }
};
