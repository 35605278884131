import {ExtensionContent} from '../../../utils/interfaces/sidebar';
import SubLink from '../../shared/SubLink/SubLink';
import styles from '../Layout.module.css';
import {getSidebarExtensionTabsForContent} from '../../../utils/sidebarExtionsTabs';
import hash from 'object-hash';
import {useUserDetails} from '../../../hooks/useUserDetails';

type SideBarExtensionProps = {
  content: ExtensionContent;
};

export const SideBarExtension = ({content}: SideBarExtensionProps) => {
  const user = useUserDetails();
  const extensionTabs = getSidebarExtensionTabsForContent(content, user);

  return (
    <>
      {extensionTabs.map(tab => (
        <SubLink
          key={hash(tab)}
          to={tab.to}
          icon={<tab.icon className={styles.sublinkIcon} />}
          text={tab.text}
        />
      ))}
    </>
  );
};
export default SideBarExtension;
