import {useAppDispatch, useAppSelector} from '../redux/store';
import LocalStorage from '../services/LocalStorage/LocalStorage';
import {setUserDetails} from '../redux/slice/authSlice';

export const useUserDetails = () => {
  let {user} = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  if (!user) {
    const fetchedUser = LocalStorage.getUserDetails();
    if (fetchedUser) {
      user = JSON.parse(fetchedUser);
      dispatch(setUserDetails(JSON.parse(fetchedUser)));
    }
  }
  return user;
};
