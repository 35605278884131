import styles from './TopBar.module.css';
import UserChip from './UserChip/UserChip';
import UserBalance from '../../shared/UserBalance/UserBalance';
import {Role} from '../../../utils/interfaces/auth';
import Logo from '../SideBar/Logo';
import {Link} from 'react-router-dom';
import {SchoolSelect} from './SchoolSelect/SchoolSelect';

type TopBarProps = {role: Role};
export const TopBar = ({role}: TopBarProps) => {
  return (
    <div className={styles.topbarContainer}>
      <div className={styles.topBarLeftContainer}>
        <Link to={'/pulpit'}>
          <Logo />
        </Link>
        <SchoolSelect />
      </div>

      {role === 'rodzic' && <UserBalance />}
      <div className={styles.topbarRight}>
        {/*{role !== 'urzad' && <ChatWidget />}*/}
        <UserChip />
      </div>
    </div>
  );
};

export default TopBar;
