import {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

export const usePagination = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(
    Number(queryParams.get('page')) || 0,
  );
  const [pageSize, setPageSize] = useState(
    Number(queryParams.get('size')) || 10,
  );
  const [searchText, setSearchText] = useState(
    queryParams.get('search') || null,
  );

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    navigate(
      `${location.pathname}?page=${currentPage}&size=${size}&search=${searchText}`,
      {replace: true},
    );
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    navigate(
      `${location.pathname}?page=${page}&size=${pageSize}&search=${searchText}`,
      {replace: true},
    );
  };

  const onSearchChange = (e: any) => {
    setSearchText(e.target.value);
    setCurrentPage(0);
    navigate(
      `${location.pathname}?page=1&size=${pageSize}&search=${e.target.value}`,
      {replace: true},
    );
  };

  return {
    currentPage,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    searchText,
  };
};
