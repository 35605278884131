import styles from './UserChip.module.css';
import {User} from 'react-feather';
import {useState, useRef, useEffect} from 'react';
import clsx from 'clsx';
import {useAppSelector} from '../../../../redux/store';
import {LogoutButton} from './LogoutButton/LogoutButton';

export const UserChip: React.FC = () => {
  const {user} = useAppSelector(state => state.auth);
  const [open, setOpen] = useState<boolean>(false);
  const useOutsideAlerter = (ref: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div onClick={() => setOpen(true)} className={styles.userChipContainer}>
      <div className={styles.iconContainer}>
        <User />
      </div>
      <h3 className={styles.title}>
        {user?.firstname} {user?.lastname}
      </h3>
      <div
        ref={wrapperRef}
        className={clsx({
          [styles.popupContainer]: true,
          [styles.popupActive]: open,
        })}
      >
        <div className={styles.popupTop}>
          <div className={styles.iconContainer}>
            <User />
          </div>
          <div className={styles.popupText}>
            <h3>
              {user?.firstname} {user?.lastname}
            </h3>
            <p>{user?.role}</p>
          </div>
        </div>
        <div className={styles.popupBottom}>
          <div className={styles.optionContainer}>
            <LogoutButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserChip;
