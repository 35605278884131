import AxiosInstance from './AxiosInstance';
import {
  SIGN_IN_USING_COOKIE,
  SIGN_IN_USING_JWT,
  PASSWORD_RESET,
  IS_AUTHENTICATED,
  LOGOUT,
  GET_PARENTS,
  GET_CANTEEN_CHILDREN,
  PATCH_CANTEEN_CHILDREN_PAYMENT_STATUS,
  GET_SCHOOLS,
  GET_JOURNALS,
  COMPUTE_SCHOOL_SETTLEMENT,
  COMPUTE_SCHOOL_SETTLEMENT_AND_SEND_EMAIL,
  RECOMPUTE_PARENT_SETTLEMENT,
} from './auth/endpoints';
import {LoginResponse} from './interface/response';
import {LoginData, ResetPasswordData} from '../../utils/interfaces/auth';
import {Parent} from '../../utils/interfaces/objects/Parent';
import {CanteenChildPaginated} from '../../utils/interfaces/objects/CanteenChild';
import {School} from '../../utils/interfaces/objects/School';
import {Journal} from '../../utils/interfaces/objects/Journal';
import {
  ComputeSchoolSettlementRequest,
  RecomputeParentSettlementRequest,
} from './report/types';
type getCanteenChildrenRequest = {
  schoolId: string;
  date: string;
  query?: string | null;
  pageNumber: number;
  pageSize: number;
};

class HTTPService {
  private axios = new AxiosInstance();

  public signInUsingCookie = (payload: LoginData) =>
    this.axios.post(SIGN_IN_USING_COOKIE, {
      login: payload.identity,
      password: payload.password,
    });
  public resetPassword = async (payload: ResetPasswordData) =>
    this.axios.post<ResetPasswordData>(PASSWORD_RESET, {
      email: payload.email,
    });
  public signInUsingJWT = (payload: LoginData) =>
    this.axios.post<LoginResponse>(SIGN_IN_USING_JWT, payload);
  public isAuthenticated = () => this.axios.get(IS_AUTHENTICATED);

  public logout = () => {
    return this.axios.get(LOGOUT);
  };

  public assignJWT = this.axios.assignJWT;
  public getUserDataFromJWT = this.axios.getUserDataFromJWT;

  public getParentForSchool = (groupIds: number[]) =>
    this.axios.get<Parent[]>(GET_PARENTS, {
      params: {groupId: groupIds.join(',')},
    });

  public getJournalsForGroup = (groupIds: number[]) =>
    this.axios.get<Journal[]>(GET_JOURNALS, {
      params: {groupId: groupIds.join(',')},
    });

  public getCanteenChildren = ({
    schoolId,
    date,
    query,
    pageNumber,
    pageSize,
  }: getCanteenChildrenRequest) => {
    return this.axios.get<CanteenChildPaginated>(
      GET_CANTEEN_CHILDREN(schoolId),
      {
        params: {date, pageSize, pageNo: pageNumber, query},
      },
    );
  };

  public fetchSchools = () => this.axios.get<School[]>(GET_SCHOOLS);

  public updateCanteenPaymentStatus = ({childId, value}: any) =>
    this.axios.patch(PATCH_CANTEEN_CHILDREN_PAYMENT_STATUS(childId, value));

  public computeSchoolSettlement = ({
    schoolId,
    year,
    month,
  }: ComputeSchoolSettlementRequest) =>
    this.axios.get(COMPUTE_SCHOOL_SETTLEMENT(schoolId, year, month));

  public computeSchoolSettlementAndSendEmail = ({
    schoolId,
    year,
    month,
  }: ComputeSchoolSettlementRequest) =>
    this.axios.post(
      COMPUTE_SCHOOL_SETTLEMENT_AND_SEND_EMAIL(schoolId, year, month),
    );

  public recomputeParentSettlement = ({
    schoolId,
    parentId,
    year,
    month,
  }: RecomputeParentSettlementRequest) =>
    this.axios.post(
      RECOMPUTE_PARENT_SETTLEMENT(schoolId, parentId, year, month),
    );
}

export default new HTTPService();
