import styles from './ReportsForm.module.css';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import DatePicker, {DateObject} from 'react-multi-date-picker';
import {pl_lowercase} from '../../../../utils/datePicker';
import {
  DEFAULT_ALL,
  DEFAULT,
  OMIT_PARAM,
  reportFormSwitches,
  reportFormTypes,
} from '../../../../utils/reportFormTypes';
import {parseDate} from '../../../../utils/dateUtils';

import {
  emptyReportSelect,
  emptyReportSwitch,
  TReportSelect,
  TReportSwitch,
} from '../../../../utils/reportTypes';
import {ReportSelect} from '../../../../utils/interfaces/reportSelect';
import ReportRequestService from '../../../../services/ReportRequestService/ReportRequestService';
import {ErrorMessage} from '../../../../utils/interfaces/errorMessage';
import {ReportSwitch} from '../../../../utils/interfaces/reportSwitch';
import ReportsSelects from './ReportsSelects';
import ReportsSwitches from './ReportsSwitches';
import ReportsButtonLine from './ReportsButtonLine';
import {Input} from '../../../index';
import {Calendar} from 'react-feather';
import {emptySelectOption} from '../../Select/Autocomplete';
import {School} from '../../../../utils/interfaces/objects/School';
import {Parent} from '../../../../utils/interfaces/objects/Parent';
import {SchoolContext} from '../../../../context/SchoolContextProvider';
import {Journal} from '../../../../utils/interfaces/objects/Journal';

const YEAR_PARAM = 'year';
const MONTH_PARAM = 'month';
const TO_DATE_PARAM = 'toDate';
const FROM_DATE_PARAM = 'fromDate';
const DATE_PARAM = 'date';
const ERROR_MESSAGE_DURATION_S = 5;

export const ReportsForm: React.FC<{
  fields: {
    date: boolean;
    dateRange: boolean;
    month: boolean;
    selectList: ReportSelect[];
    switchList?: ReportSwitch[];
  };
  schools: School[];
  parents: Parent[];
  journals: Journal[];
  fetchParentsInGroups: (groupIds: number[]) => void;
  fetchJournalsForGroup: (groupIds: number[]) => void;
  requestUrl: string;
}> = ({
  fields: {date, month, dateRange, selectList, switchList},
  schools,
  parents,
  journals,
  fetchParentsInGroups,
  fetchJournalsForGroup,
  requestUrl,
}) => {
  const {selectedSchool} = useContext(SchoolContext);
  const [selectedDate, setSelectedDate] = useState<DateObject>();
  const [selectedMonthPeriod, setSelectedMonthPeriod] = useState<DateObject>();
  const [selectedDateRange, setSelectedDateRange] = useState<
    DateObject[] | null
  >(null);
  const [selectedValue, setSelectedValue] =
    useState<TReportSelect>(emptyReportSelect);
  const [switchedValue, setSwitchedValue] =
    useState<TReportSwitch>(emptyReportSwitch);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);

  const [downloadingInProgress, setDownloadingInProgress] = useState(false);

  useEffect(() => {
    const getSelectedGroupIds = () => {
      const selectedGroupId = selectedValue[ReportSelect.GROUP].value;
      if (
        selectedGroupId ===
        reportFormTypes[ReportSelect.GROUP].defaultValue.value
      ) {
        const groupsInSchool = selectedSchool?.groups || [];
        return groupsInSchool.map(group => group.id);
      } else {
        return [+selectedGroupId];
      }
    };

    if (selectedValue[ReportSelect.GROUP].value != '') {
      const selectedGroupIds = getSelectedGroupIds();
      fetchParentsInGroups(selectedGroupIds);
      fetchJournalsForGroup(selectedGroupIds);

      setSelectedValue({
        ...selectedValue,
        [ReportSelect.PARENT]:
          reportFormTypes[ReportSelect.PARENT].defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue[ReportSelect.GROUP]]);

  const renderInputForDatePicker = (
    name: string,
    placeholder: string,
    label: string,
  ) => {
    return (
      <Input
        endAdornment={<Calendar className={styles.inputCalendar} />}
        name={name}
        type="text"
        placeholder={placeholder}
        modalInput
        label={label}
        customLabelStyle={styles.dateInputLabelOnBorder}
      />
    );
  };

  const isInputValid = (select: ReportSelect) => {
    return (
      (selectedValue[select] !== reportFormTypes[select].defaultValue &&
        selectedValue[select].value.trim() != emptySelectOption.value) ||
      [DEFAULT_ALL, DEFAULT, OMIT_PARAM].includes(selectedValue[select].value) // if it is default value then does it have non-blank value property?
    );
  };

  const pushErrorMessage = (message: string) => {
    const messageObj: ErrorMessage = {
      id: new Date().getMilliseconds(),
      message: message,
    };
    setErrorMessage(messageObj);
    setTimeout(() => {
      setErrorMessage((prevMessage: ErrorMessage | null) => {
        if (prevMessage?.id === messageObj.id) {
          return null;
        } else return prevMessage;
      });
    }, ERROR_MESSAGE_DURATION_S * 1000);
  };

  const createParamsForRequest = () => {
    const params = new URLSearchParams();

    if (date && selectedDate) {
      params.append(DATE_PARAM, parseDate(selectedDate.toDate()));
    }
    if (dateRange && selectedDateRange) {
      params.append(FROM_DATE_PARAM, parseDate(selectedDateRange[0].toDate()));
      params.append(TO_DATE_PARAM, parseDate(selectedDateRange[1].toDate()));
    }
    if (month && selectedMonthPeriod) {
      params.append(MONTH_PARAM, selectedMonthPeriod.month.toString());
      params.append(YEAR_PARAM, selectedMonthPeriod.year.toString());
    }

    if (!selectList.includes(ReportSelect.SCHOOL_WITH_ALL)) {
      if (selectedSchool) {
        params.append('schoolId', selectedSchool.id.toString());
      }
    }

    selectList.forEach(select => {
      if (selectedValue[select].value != OMIT_PARAM && isInputValid(select)) {
        const paramName = reportFormTypes[select].paramName;
        const paramValue = selectedValue[select].value;
        params.append(paramName, paramValue);
      }
    });
    switchList
      ?.filter(switchEl => switchedValue[switchEl])
      .forEach(switchEl => {
        const paramName = reportFormSwitches[switchEl].paramName;
        const paramValue = reportFormSwitches[switchEl].value;
        params.append(paramName, paramValue);
      });

    return params;
  };

  const onSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
  }, []);

  const areInputsValid = () => {
    if (date && !selectedDate) {
      return false;
    }
    if (
      dateRange &&
      !(
        Array.isArray(selectedDateRange) &&
        selectedDateRange[0] &&
        selectedDateRange[1]
      )
    ) {
      return false;
    }
    if (month && !selectedMonthPeriod?.toDate()) {
      return false;
    }
    for (const select of selectList) {
      if (!isInputValid(select)) {
        return false;
      }
    }

    return true;
  };

  const getReportClickHandler = () => {
    if (areInputsValid()) {
      const urlParams = createParamsForRequest();
      ReportRequestService.downloadReport(
        requestUrl,
        urlParams,
        setDownloadingInProgress,
        pushErrorMessage,
      );
    } else {
      console.warn('inputs invalid');
    }
  };

  return (
    <form onSubmit={onSubmit} className={styles.formContainer}>
      {date && (
        <DatePicker
          locale={pl_lowercase}
          value={selectedDate}
          onChange={(data: DateObject) => setSelectedDate(data)}
          multiple={false}
          containerClassName={styles.datePickerContainer}
          className={styles.inputClass}
          render={renderInputForDatePicker('date', 'Wybierz dzień', 'Dzień')}
        />
      )}
      {month && (
        <DatePicker
          locale={pl_lowercase}
          multiple={false}
          value={selectedMonthPeriod}
          onChange={(data: DateObject) => setSelectedMonthPeriod(data)}
          onlyMonthPicker
          containerClassName={styles.datePickerContainer}
          render={renderInputForDatePicker(
            'month',
            'Wybierz miesiąc',
            'Miesiąc',
          )}
        />
      )}
      {dateRange && (
        <DatePicker
          locale={pl_lowercase}
          range
          value={selectedDateRange}
          onChange={(data: DateObject[]) => setSelectedDateRange(data)}
          containerClassName={styles.datePickerContainer}
          render={renderInputForDatePicker(
            'date-range',
            'Wybierz zakres dat',
            'Zakres',
          )}
        />
      )}
      <ReportsSelects
        selectList={selectList}
        selectedSchool={selectedSchool}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        schools={schools}
        parents={parents}
        journals={journals}
        fetchParentsInGroups={fetchParentsInGroups}
        fetchJournalsForGroup={fetchJournalsForGroup}
      />

      {switchList && (
        <ReportsSwitches
          switchList={switchList}
          switchedValue={switchedValue}
          setSwitchedValue={setSwitchedValue}
        />
      )}

      <ReportsButtonLine
        onClick={getReportClickHandler}
        boolean={areInputsValid()}
        downloadingInProgress={downloadingInProgress}
      />
      {errorMessage && (
        <div className={styles.errorMessage}>
          <p>{errorMessage.message}</p>
        </div>
      )}
    </form>
  );
};

export default ReportsForm;
